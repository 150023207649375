import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "react-bootstrap";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function UserAudiocallList(params) {
  console.log("params", params.userAudiocallList);
  const audiocallList = params.userAudiocallList;
  console.log("paymentList", audiocallList);
  const { t } = useTranslation();

  const originalDate = new Date();
  const listtemp = audiocallList.map((obj) => {
    const startTime = new Date(obj.start_time);
    const formattedStartTime = format(startTime, "d MMM yyyy, HH:mm");
    return {
      ...obj,
      start_time: formattedStartTime,
    };
  });

  console.log("start_time", listtemp);
  const columns = [
    { field: "callFrom", headerName: "CONSULTANT", width: 200 },
    // { field: "offerName", headerName: t("TXT.OFF.NM.UP"), width: 200 },
    { field: "call_type", headerName: "CALL TYPE", width: 200 },
    { field: "start_time", headerName: "START TIME", width: 200 },
    { field: "duration", headerName: "DURATION", width: 150 },
    {
      field: "proposedCallDuration",
      headerName: "PROPOSED DURATION",
      width: 200,
    },
    { field: "status", headerName: "STATUS", width: 200 },
  ];

  return (
    <div>
      <center>
        <h4>
          <u>Audio Call Details</u>
        </h4>
      </center>
      {listtemp.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={listtemp}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          <div className="mobileCard">
            {listtemp.map((i, j) => (
              <Card
                className="mobileCard all_border"
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Consultant:</b> {i.callFrom}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Call Type:</b> {i.call_type}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Start Time:</b> {i.start_time}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Duration:</b> {i.duration}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Proposed Duration:</b> {i.proposedCallDuration}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Status:</b> {i.status}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}
    </div>
  );
}

export default UserAudiocallList;
