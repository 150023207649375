import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../../component/Api";
import { format } from "date-fns";
import Toast from "../../component/Toast";

function AdminChatHistory() {
  const [updateList, setUpdateList] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get(`initiateChat/getAllChatHistoryInAdmin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setChatHistory(res.data);
          setUpdateList(false);
          console.log("setChatHistory", res.data);
        });
    } catch (e) {
      console.log("setAllOffer", e);
    }
  }, [updateList]);

  const handleUpdateStatus = async (data, status) => {
    try {
      const res = await vasthuUrl.put(
        `updateChatStatus`,
        {
          id: data.id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(`Status Updated Successfully !!!`);
      setMessageType("success");
      setUpdateList(true);
    } catch (error) {
      alert("Failed to Update");
      setMessageType("error");
    }
  };

  const columns = [
    { field: "username", headerName: "USER NAME", width: 120 },
    { field: "conName", headerName: "CONSULTANT NAME", width: 150 },
    { field: "status", headerName: "STATUS", width: 150 },
    { field: "duration", headerName: "CHATTED DURATION", width: 180 },
    { field: "proposedChatDuration", headerName: "TOTAL DURATION", width: 150 },
    { field: "meetingCode", headerName: "MEETING CODE", width: 120 },
    {
      field: "chatDate",
      headerName: "CHAT DATE",
      renderCell: (params) => {
        const date = new Date(params.row.chatDate);
        return format(date, "dd-MM-yyyy");
      },
      width: 120,
    },
    { field: "chatStartTime", headerName: "CHAT START TIME", width: 160 },
    { field: "connectStartTime", headerName: "CONNECT START TIME", width: 170 },
    { field: "connectEndTime", headerName: "CONNECT END TIME", width: 170 },
    {
      field: "actions",
      headerName: "ACTION",
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          {params.row.status == "in-progress" ? (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row, "DISCONNECTED")}
            >
              Update
            </Button>
          ) : (
            "-"
          )}
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <div>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />

      {!loading ? (
        chatHistory.length > 0 ? (
          <>
            <center>
              <h2>Chat History</h2>
            </center>
            <center className="datagrid">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  rows={[...chatHistory].reverse()}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>
            <div className="mobileCard">
              {chatHistory.map((i, j) => (
                <Card
                  className="mobileCard all_border"
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>User Name:</b> {i.username}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Consultant Name:</b> {i.consultantname}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Status:</b> {i.status}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Duration:</b> {i.duration}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Meeting Code:</b> {i.meetingCode}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Chat Date:</b> {i.chatDate}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Chat Start Time:</b> {i.chatStartTime}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Connect Start Time:</b> {i.connectStartTime}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Connect End Time:</b> {i.connectEndTime}
                    </Card.Subtitle>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {i.status == "in-progress" ? (
                        <Button
                          className="mx-1 all_btn"
                          onClick={() => handleUpdateStatus(i, "DISCONNECTED")}
                        >
                          Update
                        </Button>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </div>
  );
}

export default AdminChatHistory;
