import React, { Suspense, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import ServiceList from "../component/serviceList";
import { useTranslation } from "react-i18next";

function CustomerSpecialPricing(params) {
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const crmUuid = params && params.crmUuid;
  const uuid =
    role == "ROLE_CRM" ? crmUuid : window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const [specialOffers, setSpecialOffers] = useState([]);
  console.log("params", params);
  console.log("uuid", uuid);

  const fetchOffers = async () => {
    try {
      const url =
        params &&
        params.selectService !== null &&
        params.selectService !== undefined
          ? `offer/getalluseractivespecialoffer/${uuid}/${params.selectService}`
          : `offer/getalluseractivespecialoffers/${uuid}`;

      const response = await vasthuUrl.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSpecialOffers(response.data);
      console.log(response.data);
    } catch (e) {
      console.error("SERVER ERROR", e);
      setSpecialOffers([]);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [params, uuid, token]);

  return (
    <Suspense fallback={<Spinner />}>
      <Container
        fluid
        style={{
          padding: 20,
          background: "linear-gradient(90deg, #ffbb33 0%, #ffbb33 100%)",
        }}
      >
        <div className=" my-1 g-1">
          <center>
            <h2>{t("USR.PR.OTTAB.TL1")}</h2>
          </center>
          {specialOffers.length > 0 ? (
            <Suspense fallback={<Spinner />}>
              <ServiceList data={specialOffers} crmUuid={crmUuid}/>
            </Suspense>
          ) : (
            <center>
              <h3>{t("USR.PR.OTTAB.HTXT1")}</h3>
            </center>
          )}
        </div>
      </Container>
    </Suspense>
  );
}

export default CustomerSpecialPricing;
