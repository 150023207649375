import Axios from "axios";

// export const videoServerUrl = "ws://localhost:3001"
export const videoServerUrl= "ws://vasthuparam.com:3001 "
// export const videoServerUrl= "ws://122.165.74.116:3001 "

//Airtel setup_testing-------api.js
// export const videoServerUrl= "ws://122.165.195.50:3001"

export const vasthuUrl = Axios.create({
  baseURL: `${process.env.REACT_APP_BAS_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-control-allow-credentials": "false",
  },
  withCredentials: false,
});
