import React, { useEffect, useState } from "react";
import {
  default as Modal2,
  default as Modal3,
  default as Toast,
} from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "./BillDeskPay";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  ListGroup,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { AiOutlineStar } from "react-icons/ai";
import { BiBadgeCheck } from "react-icons/bi";
import { GoProject } from "react-icons/go";
import { GrUserExpert } from "react-icons/gr";
import { MdOutlineLanguage } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ToastMsg from "./Toast";
import FreeUsed from "./FreeUsed";
import DatePicker from "react-date-picker";
import { TimePicker } from "antd";
import validator from "validator";
import { format, parse } from "date-fns";
import dayjs from "dayjs";
import { FaFemale, FaMale } from "react-icons/fa";

const SearchList = ({ filteredPersons, service, wallet }) => {
  console.log("filteredPersons", filteredPersons);
  console.log("filteredPersons", filteredPersons.length);
  const walletBalance = wallet && wallet.balance;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const lang = window.localStorage.getItem("i18nextLng");
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const consultantId = window.sessionStorage.getItem("consultantId");
  const uuid = window.sessionStorage.getItem("userId");
  const duration = window.sessionStorage.getItem("AudioCallDuration");
  const [profList, setProfList] = useState([]);
  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const [consulName, setConsulName] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState({});
  const [selectedConsultantService, setSelectedConsultantService] = useState(
    service
  );
  const [defaultAudioOffer, setDefaultAudioOffer] = useState({});
  const [defaultAudioOffer15, setDefaultAudioOffer15] = useState({});
  const [defaultAudioOffer30, setDefaultAudioOffer30] = useState({});
  let subtitle;
  const [loading, setLoading] = useState(false);
  const [selectedAudioOffer, setSelectedAudioOffer] = useState(7);
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [consultantServiceList, setConsultantServiceList] = useState([]);
  const [defaultHomeOffer, setDefaultHomeOffer] = useState({
    offerId: 0,
    offerPrice: 0,
    serviceId: 0,
  });
  const [formData, setFormData] = useState({
    duration: 0,
    price: 0,
  });
  const [conUuid, setConUuid] = useState();
  const [conName, setConName] = useState();
  const [conImgUrl, setConImgUrl] = useState();
  const [consultantStats, setConsultantStats] = useState({});
  const [userReview, setUserReview] = useState([]);
  const [fiveStars, setFiveStars] = useState(0);
  const [fourStars, setFourStars] = useState(0);
  const [threeStars, setThreeStars] = useState(0);
  const [twoStars, setTwoStars] = useState(0);
  const [oneStar, setOneStar] = useState(0);
  const [rating, setRating] = useState(3);
  const [freeCallStatus, setFreeCallStatus] = useState("");
  const [reviewDesc, setReviewDesc] = useState("");
  console.log("profList", profList);
  const [bookingStatus, setBookingStatus] = useState("");
  const [chatUserDetailsModel, setChatUserDetailsModel] = useState(false);
  console.log("consultantId", consultantId);

  console.log("filteredPersons", filteredPersons);
  const { consultantUuid } = location.state || {};
  const [saveMatchingDetailes, setSaveMatchingDetailes] = useState();
  const [savePersonalDetailes, setSavePersonalDetailes] = useState();
  const [showForm, setShowForm] = useState(false);
  const [showFemaleForm, setShowFemaleForm] = useState(false);
  const [singleForm, setSingleForm] = useState(false);
  const [showAddMaleButton, setShowAddMaleButton] = useState(true);
  const [showAddFemaleButton, setShowAddFemaleButton] = useState(true);

  // useEffect(() => {
  //   if (!Array.isArray(saveMatchingDetailes)) {
  //     setSaveMatchingDetailes([]);
  //   }
  // }, [saveMatchingDetailes]);
  console.log("saveMatchingDetailes:", saveMatchingDetailes);

  //Personal Consultation Details
  const [userName, setUserName] = useState();
  const [userPlace, setUserPlace] = useState();
  const [dateValue, setDateValue] = useState();
  console.log("dateValue", dateValue);
  const [timeValue, setTimeValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Personal");
  const [gender, setGender] = useState("male");
  console.log("gender", gender);

  const handleDateChange = (date) => {
    setDateValue(date);
    setTimeValue(null);
  };

  const handleTimeChange = (key, time) => {
    const newTime = time ? dayjs(time) : null;

    if (key === "general") {
      setTimeValue(newTime);
    } else if (key === "male") {
      setMaleTimeValue(time ? time.format("hh:mm A") : null);
    } else if (key === "female") {
      setFeTimeValue(newTime);
    }
  };

  const getDisabledHours = () => {
    if (dateValue && isToday(dateValue)) {
      const currentHour = new Date().getHours();
      return Array.from({ length: 24 }, (_, i) =>
        i > currentHour ? i : null
      ).filter(Number.isInteger);
    }
    return [];
  };

  const isToday = (someDate) => {
    if (!someDate || !(someDate instanceof Date) || isNaN(someDate)) {
      return false;
    }
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const getDisabledMinutes = (selectedHour) => {
    if (dateValue && isToday(dateValue)) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (selectedHour === currentHour) {
        const currentMinute = currentDate.getMinutes();
        return Array.from({ length: 60 }, (_, i) =>
          i > currentMinute ? i : null
        ).filter(Number.isInteger);
      }
    }
    return [];
  };

  //Male Matching Details
  const [maleUserName, setMaleUserName] = useState();
  const [maleuserPlace, setMaleUserPlace] = useState();
  const [maledateValue, setMaleDateValue] = useState();
  const [maletimeValue, setMaleTimeValue] = useState(null);
  console.log("maletimeValue", maletimeValue);
  console.log("fetimeValue", fetimeValue);
  const [maleselectedCard, setMaleSelectedCard] = useState(null);
  const [femaleSelectedCard, setFemaleSelectedCard] = useState(null);
  const [singleSelectedCard, setSingleSelectedCard] = useState(null);
  console.log("singleSelectedCard", singleSelectedCard);
  console.log("maleselectedCard", maleselectedCard);
  console.log("femaleSelectedCard", femaleSelectedCard);

  const handleSelection = (user, gender) => {
    console.log("Selected Gender:", gender);
    console.log("User Object:", user);

    if (gender === "male") {
      setMaleSelectedCard({
        name: user.maleUserName,
        birthDate: user.maleBirthDate,
        birthTime: user.maleBirthTime,
        birthPlace: user.maleBirthPlace,
        useExisting: true,
      });
      setShowAddMaleButton(false);
      setMaleUserName(user.maleUserName);
      setMaleDateValue(user.maleBirthDate);
      setMaleTimeValue(user.maleBirthTime);
      setMaleUserPlace(user.maleBirthPlace);
    } else if (gender === "female") {
      setFemaleSelectedCard({
        name: user.femaleUserName,
        birthDate: user.femaleBirthDate,
        birthTime: user.femaleBirthTime,
        birthPlace: user.femaleBirthPlace,
        useExisting: true,
      });

      setFeUserName(user.femaleUserName);
      setFeDateValue(user.femaleBirthDate);
      setFeTimeValue(user.femaleBirthTime);
      setFeUserPlace(user.femaleBirthPlace);
    } else if (gender === "single") {
      setSingleSelectedCard({
        name: user.userName,
        birthDate: user.birthDate,
        birthTime: user.birthTime,
        birthPlace: user.userBirthstate,
        useExisting: true,
      });

      setUserName(user.userName);
      setDateValue(user.birthDate);
      setTimeValue(user.birthTime);
      setUserPlace(user.userBirthstate);
    }
  };

  useEffect(() => {
    console.log(
      "Selected Male User Details:",
      maleselectedCard,
      singleSelectedCard,
      femaleSelectedCard
    );
  }, [maleselectedCard, singleSelectedCard, femaleSelectedCard]);

  console.log("maleselectedCard", maleselectedCard);
  console.log("femaleSelectedCard", femaleSelectedCard);

  const handleMaleDateChange = (date) => {
    setMaleDateValue(date);
    setMaleTimeValue(null);
  };

  //Female Matching Details
  const [feuserName, setFeUserName] = useState();
  const [feuserPlace, setFeUserPlace] = useState();
  const [fedateValue, setFeDateValue] = useState();
  const [fetimeValue, setFeTimeValue] = useState(null);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`getAstroDetailsFormatching/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          // const data = res.data;
          // if (data.length > 0) {
          if (res.data.message == "NOT A VALID USER") {
            setShowForm(true);
            setShowFemaleForm(true);
          } else {
            setSaveMatchingDetailes(res.data);
          }
        });
    } catch (e) {
      alert("SERVER ERROR");
    }
    try {
      vasthuUrl
        .get(`getAstroPersonalDetails/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          // const data = res.data;
          // if (data.length > 0) {
          if (res.data.message == "NOT A VALID USER") {
            setSingleForm(true);
          } else {
            setSavePersonalDetailes(res.data);
          }
        });
    } catch (e) {
      alert("SERVER ERROR");
    }
  }, []);

  const handleFemaleDateChange = (date) => {
    console.log("date", date);
    setFeDateValue(date);
    setFeTimeValue(null);
  };

  console.log("fedateValue", fedateValue);
  console.log("fetimeValue", fetimeValue);

  const handleAddMatchingDetailes = (isExisting) => {
    if (!maleUserName && !feuserName) {
      alert("Please select any one pair or add male/female to submit");
      setMessageType("error");
      return;
    }
    if (!maleUserName) {
      alert("Please enter your male name.");
      setMessageType("error");
      return;
    }
    if (!maledateValue) {
      alert("Please enter your male birth date.");
      setMessageType("error");
      return;
    }
    if (!maletimeValue) {
      alert("Please enter your male birth time.");
      setMessageType("error");
      return;
    }
    if (!maleuserPlace) {
      alert("Please enter your male birth place.");
      setMessageType("error");
      return;
    }
    if (!feuserName) {
      alert("Please enter Female your name.");
      setMessageType("error");
      return;
    }
    if (!fedateValue) {
      alert("Please enter your Female birth date.");
      setMessageType("error");
      return;
    }
    if (!fetimeValue) {
      alert("Please enter your Female birth time.");
      setMessageType("error");
      return;
    }
    if (!feuserPlace) {
      alert("Please enter your Female birth place.");
      setMessageType("error");
      return;
    }
    console.log("handleAddMatchingDetailes called!");
    const consultantuuiId = window.sessionStorage.getItem("consultantId");
    setChatUserDetailsModel(false);
    console.log("consultantuuiId from sessionStorage:", consultantuuiId);
    AddMatchingDetailes(isExisting, consultantuuiId);
  };

  const AddMatchingDetailes = async (isExisting, consultantuuiId) => {
    console.log("Function AddMatchingDetailes started...");
    const formattedMaleTime =
      maletimeValue && dayjs(maletimeValue, ["HH:mm", "hh:mm A"]).isValid()
        ? dayjs(maletimeValue, ["HH:mm", "hh:mm A"]).format("HH:mm:ss")
        : "00:00:00";

    const formattedFemaleTime =
      fetimeValue && dayjs(fetimeValue, ["HH:mm", "hh:mm A"]).isValid()
        ? dayjs(fetimeValue, ["HH:mm", "hh:mm A"]).format("HH:mm:ss")
        : "00:00:00";

    console.log("Formatted maleBirthTime:", formattedMaleTime);
    console.log("Formatted femaleBirthTime:", formattedFemaleTime);

    // Ensure male and female date values exist before formatting
    const finalMaleDateValue = maledateValue
      ? dayjs(maledateValue).format("YYYY-MM-DD")
      : maleselectedCard.maleBirthDate
      ? dayjs(maleselectedCard.maleBirthDate).format("YYYY-MM-DD")
      : null;

    const finalFeDateValue = fedateValue
      ? dayjs(fedateValue).format("YYYY-MM-DD")
      : femaleSelectedCard.femaleBirthDate
      ? dayjs(femaleSelectedCard.femaleBirthDate).format("YYYY-MM-DD")
      : null;

    console.log("Formatted maleBirthDate:", finalMaleDateValue);
    console.log("Formatted femaleBirthDate:", finalFeDateValue);

    console.log("Sending data:", {
      userId,
      to_Id: consultantuuiId,
      maleUserName,
      femaleUserName: feuserName,
      maleBirthDate: finalMaleDateValue,
      femaleBirthDate: finalFeDateValue,
      maleBirthPlace: maleuserPlace,
      femaleBirthPlace: feuserPlace,
      maleBirthTime: formattedMaleTime,
      femaleBirthTime: formattedFemaleTime,
      useExistingMale: Boolean(isExisting),
      useExistingFemale: Boolean(isExisting),
    });

    try {
      if (!maleUserName || !maleuserPlace || !feuserName || !feuserPlace) {
        console.error("Missing required fields!");
        alert("Please enter all details");
        return;
      }

      console.log("Making API request...");
      const res = await vasthuUrl.post(
        `astroCustomerDetailsForMatching`,
        {
          userId: userId,
          to_Id: consultantuuiId,
          maleUserName: maleUserName,
          femaleUserName: feuserName,
          maleBirthDate: finalMaleDateValue,
          femaleBirthDate: finalFeDateValue,
          maleBirthPlace: maleuserPlace,
          femaleBirthPlace: feuserPlace,
          maleBirthTime: formattedMaleTime,
          femaleBirthTime: formattedFemaleTime,
          useExistingMale: Boolean(isExisting),
          useExistingFemale: Boolean(isExisting),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("API response:", res.data);
      alert(res.data.message);
      setMessageType("success");
      alert("Successfully Uploaded Your Details!");
      startCountdown();
    } catch (error) {
      console.error("Error saving details:", error);
      alert("An error occurred while saving details.");
    }
  };

  const handleButtonClick = (useExisting) => {
    if (!userName) {
      alert("Please enter your name.");
      setMessageType("error");
      return;
    }
    if (!dateValue) {
      alert("Please enter your birth date.");
      setMessageType("error");
      return;
    }
    if (!timeValue) {
      alert("Please enter your birth time.");
      setMessageType("error");
      return;
    }
    if (!userPlace) {
      alert("Please enter your birth place.");
      setMessageType("error");
      return;
    }
    const consultantuuiId = window.sessionStorage.getItem("consultantId");
    setChatUserDetailsModel(false);
    addPersonalConsultationDetails(consultantuuiId, useExisting);
  };

  const addPersonalConsultationDetails = async (
    consultantuuiId,
    useExisting
  ) => {
    const finalUserName = userName || singleSelectedCard.name;
    const finalDateValue = dateValue || singleSelectedCard.birthDate;
    const finalTimeValue = timeValue
      ? dayjs(timeValue, "HH:mm:ss").format("HH:mm:ss")
      : singleSelectedCard.birthTime || "00:00:00";
    const finalUserPlace = userPlace || singleSelectedCard.birthPlace;
    const finalExisting = useExisting
      ? useExisting
      : singleSelectedCard && singleSelectedCard.useExisting
      ? singleSelectedCard.useExisting
      : false;
    console.log("finalExisting", finalExisting);

    console.log("finalExisting:", finalExisting);

    try {
      const response = await vasthuUrl.post(
        "astroCustomerDetailsForPersonal",
        {
          userId: userId,
          to_Id: consultantuuiId,
          userName: finalUserName,
          userBirthstate: finalUserPlace,
          birthTime: finalTimeValue,
          birthDate: finalDateValue,
          useExisting: finalExisting,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response:", response.data);
      alert(response.data.message || "Successfully Uploaded Your Details!");

      setMessageType("success");

      // Reset fields
      setUserName("");
      setUserPlace("");
      setDateValue("");
      setTimeValue("");

      // Redirect after delay
      startCountdown();
    } catch (error) {
      console.error("Error in addPersonalConsultationDetails:", error);
      alert("An error occurred. Please try again.");
      setMessageType("error");
    }
  };

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy");
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return "";
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00 AM";

    try {
      const parsedTime = parse(timeString, "HH:mm:ss", new Date());

      return format(parsedTime, "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error.message);
      return "00:00 AM";
    }
  };

  useEffect(() => {
    console.log("Updated State:", { singleSelectedCard, singleForm });
  }, [singleSelectedCard, singleForm]);

  const backgroundColor = selectedOption === "Personal" ? "#FFD700" : "#90EE90";

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  // Consultant Busy MODAL
  const [toast, setToast] = useState(false);

  const openToastModal = (value) => {
    setToast(true);
    setWaitingTime(Math.round(value.waitingTime));
  };

  const closeTosastBtn = () => {
    setToast(false);
    window.sessionStorage.removeItem("pendingAction");
  };

  //Audio call duration MODAL
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    console.log("afterOpenModal");
  }

  function closeModal() {
    setIsOpen(false);
    window.sessionStorage.removeItem("pendingAction");
  }

  //Service MODAL
  const [serviceModal, setServiceModal] = React.useState(false);

  function OpenServiceModal() {
    setServiceModal(true);
  }
  function afterServiceModal() {
    console.log("afterOpenModal");
  }

  function closeServiceModal() {
    setServiceModal(false);
    window.sessionStorage.removeItem("pendingAction");
  }

  // Consultant Review Confirm MODAL
  const [model1, setModel1] = useState(false);

  const modelClose1 = () => {
    setModel1(false);
    setConUuid();
    setConName();
    setConImgUrl();
  };

  const modelOpen1 = (id, name, url) => {
    setModel1(true);
    setConUuid(id);
    setConName(name);
    setConImgUrl(url);
  };

  // Consultant Review  MODAL
  const [model, setModel] = useState(false);

  const modelClose = () => {
    setModel(false);
    setModel1(false);
    setConUuid();
    setConName();
    setConImgUrl();
  };

  const modelOpen = () => {
    setModel(true);
  };

  //Check Booking Status
  const [checkBookStatus, setCheckBookStatus] = useState(false);

  const openCheckBookStatus = () => {
    setCheckBookStatus(true);
  };

  const closeCheckBookStatus = () => {
    setCheckBookStatus(false);
    window.sessionStorage.removeItem("pendingAction");
  };

  //Check Booking Status
  const [callType, setCallType] = useState(false);

  const openCallType = () => {
    setCallType(true);
  };

  const closeCallType = () => {
    setCallType(false);
    window.sessionStorage.removeItem("pendingAction");
  };

  const [consultantUuids, setConsultantUuids] = useState(null);
  console.log("consultantUuids", consultantUuids);

  const handleUserDetailesCloseModel = () => {
    setMaleSelectedCard(null);
    setFemaleSelectedCard(null);
    setSingleSelectedCard(null);
    setSingleForm(null);
    setShowForm(null);
    setShowFemaleForm(null);
    setSelectedOption("Personal");
    setChatUserDetailsModel(false);
  };

  const chatAlertCall = (id) => {
    console.log("id", id);
    vasthuUrl
      .post(
        "/vasthu/user/calltoconsultant",
        { to: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        console.log("Call ringing...");
      })
      .catch((error) => {
        console.error(
          "Error in chatAlertCall:",
          error.response.data || error.message
        );
      });
  };

  const handleClick = () => {
    setChatUserDetailsModel(true);
  };

  //Free Service Used Modal
  const [usedFreeModal, setUsedFreeModal] = useState(0);

  //review enter card
  const handleSendReview = async () => {
    try {
      const response = await vasthuUrl.post(
        "/saveconsultantreview",
        {
          user_uuid: userId,
          consultant_uuid: conUuid,
          user_rating: rating,
          review_description: reviewDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert(t("USR.REV.SUC.ALT"));
        setMessageType("success");
        setReviewDesc("");
      }
    } catch (error) {
      setMessageType("error");
      alert("Error submitting review:", error);
    }
  };

  // booking - end
  useEffect(() => {
    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores", res);
        setDefaultAudioOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio15`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores15", res);
        setDefaultAudioOffer15({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer15({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio30`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores30", res);
        setDefaultAudioOffer30({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer30({}));
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/getvasthuservices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          let consultations = res.data.filter(
            (obj) => obj.serviceType == "CONSULTATION"
          );
          console.log(
            "services_list_consultaions" + consultations[0].serviceType
          );
          setServiceData(consultations);
        });

      vasthuUrl
        .get(`/info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setMessageType("error");
      setLoading(false);
    }
  }, []);

  const executePendingAction = () => {
    const pendingAction = window.sessionStorage.getItem("pendingAction");
    console.log("pendingAction", pendingAction);
    if (pendingAction == "ConsultantHomeBook") {
      const consultantId = window.sessionStorage.getItem("consultantId");
      const consultantName = window.sessionStorage.getItem("consultantName");
      getConsutantServices(consultantId);
      setConsulName(consultantName);
    } else if (pendingAction == "HomeChatorCall") {
      const consultantId = window.sessionStorage.getItem("consultantId");
      const consultantName = window.sessionStorage.getItem("consultantName");
      const consultantRate = window.sessionStorage.getItem("consultantRate");
      GetBookStatus(consultantId, consultantRate);
      setConsulName(consultantName);
    } else if (pendingAction == "HomeChatorCallafterLogin") {
      GetWelletService();
    }
  };

  // Call executePendingAction after login
  useEffect(() => {
    executePendingAction();
  }, []);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/vasthu/user/getuserfreecallstatus/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFreeCallStatus(res.data.freeCallUsed);
          if (res.data.freeCallUsedstatus == "Completed") {
            setUsedFreeModal((prev) => prev + 1);
          }
        });
    } catch (e) {
      console.log("service", e);
    }
  }, [userId]);

  const checkLoginAndGetConsultant = (id, name, type, rate) => {
    if (window.sessionStorage.getItem("token") == null) {
      if (type == "Book") {
        window.sessionStorage.setItem("pendingAction", "ConsultantHomeBook");
      } else if (
        type == "Call" ||
        type == "Chat" ||
        type == "freeCall" ||
        type == "freeChat"
      ) {
        window.sessionStorage.setItem("pendingAction", "HomeChatorCall");
      }
      window.sessionStorage.setItem("consultantId", id);
      window.sessionStorage.setItem("consultantName", name);
      window.sessionStorage.setItem("consultantRate", rate);
      window.sessionStorage.setItem("consultantType", type);
      alert(t("USR.LOG.MSG"));
      setMessageType("error");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      window.sessionStorage.setItem("consultantId", id);
      window.sessionStorage.setItem("consultantRate", rate);
      window.sessionStorage.setItem("consultantType", type);
      if (
        type == "Call" ||
        type == "Chat" ||
        type == "freeCall" ||
        type == "freeChat"
      ) {
        GetBookStatus(id, rate);
      } else if (type == "Book") {
        getConsutantServices(id);
      }
      setConsulName(name);
    }
  };

  const GetBookStatus = (id, rate) => {
    const type = window.sessionStorage.getItem("consultantType");
    try {
      vasthuUrl
        .get(`/availableslots/getconsultantcallbookedstatus/${id}`, {
          params: { userUuid: userId },
        })
        .then((res) => {
          setBookingStatus(res.data);
          console.log("res.data", res.data);
          if (
            res.data.status == "CALLCOMING" ||
            res.data.status == "DONT CALL"
          ) {
            openCheckBookStatus();
          } else {
            GetConsultantCallStatus(id, rate);
          }
        });
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const handleProceed = () => {
    const id = window.sessionStorage.getItem("consultantId");
    const consultantRate = window.sessionStorage.getItem("consultantRate");
    GetConsultantCallStatus(id, consultantRate);
    closeCheckBookStatus();
  };

  const GetConsultantCallStatus = (id) => {
    const type = window.sessionStorage.getItem("consultantType");
    setLoading(true);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id,
          user_uuid: userId,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          setLoading(false);
          if (res.data.proCurrentCallStatus === "in-progress") {
            openToastModal(res.data);
          } else {
            if (type == "Chat") {
              GetWelletService();
            } else if (type == "Call") {
              openCallType();
            } else if (type == "freeCall" || type == "freeChat") {
              CheckFreeStatus(id, type);
            }
          }
        });
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleProceedCall = (type) => {
    if (type == "duration") {
      setTimeout(() => {
        openModal();
      }, 5000);
    } else if (type == "wallet") {
      GetWelletService();
    }
    closeCallType();
  };

  const GetWelletService = () => {
    window.sessionStorage.removeItem("pendingAction");
    window.sessionStorage.removeItem("returnurl");
    const id = window.sessionStorage.getItem("consultantId");
    const consultantRate = window.sessionStorage.getItem("consultantRate");
    const type = window.sessionStorage.getItem("consultantType");
    if (consultantRate > 0) {
      const minCost = consultantRate * 5;
      try {
        vasthuUrl
          .get(`/getuserbalance/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data && res.data.balance > 0) {
              console.log("res.data", res.data);
              if (res.data.balance >= minCost) {
                if (type == "Chat") {
                  handleClick();
                  chatAlertCall(id);
                  console.log("chat proceed");
                } else {
                  try {
                    vasthuUrl
                      .post(`/vasthu/user/callFromUserToConsultant`, {
                        from: id,
                        to: userId,
                      })
                      .then((res) => {
                        console.log("callllllllllllll", res);
                        if (res.status == 200) {
                          alert(t("USR.CAL.PROCED.BTN"));
                          setMessageType("success");
                          setTimeout(() => {
                            navigate("/audiocallstatus", {
                              state: { callStatus: "USR.CAL.PROCED.BTN" },
                            });
                          }, 3000);
                        } else {
                          alert(t("USR.CAL.FAILD.BTN"));
                          setMessageType("error");
                          setTimeout(() => {
                            navigate("/audiocallstatus", {
                              state: { callStatus: "USR.CAL.FAILD.BTN" },
                            });
                          }, 3000);
                        }
                      });
                  } catch (e) {
                    console.log("err", e);
                  }
                  console.log("call proceed");
                }
              } else {
                alert(
                  `${t("USR.BAL.MINCOST.CONST.ALT")} ₹ ${minCost} ${t(
                    "USR.THIS.CONSULTANT"
                  )}.`
                );
                setMessageType("error");
                window.sessionStorage.setItem("consultantId", id);
                window.sessionStorage.setItem("consultantType", type);
                window.sessionStorage.setItem("returnurl", location.pathname);
                window.sessionStorage.setItem(
                  "pendingAction",
                  "HomeChatorCallafterLogin"
                );
                setTimeout(() => {
                  navigate("/walletoffer");
                }, 3000);
              }
              window.sessionStorage.removeItem("pendingAction");
            } else {
              alert(t("USR.INSF.BAL.ALRT.MSG"));
              setMessageType("error");
              window.sessionStorage.setItem("consultantId", id);
              window.sessionStorage.setItem("consultantType", type);
              window.sessionStorage.setItem("returnurl", location.pathname);
              window.sessionStorage.setItem(
                "pendingAction",
                "HomeChatorCallafterLogin"
              );
              setTimeout(() => {
                navigate("/walletoffer");
              }, 3000);
              window.sessionStorage.removeItem("pendingAction");
            }
          });
      } catch (e) {
        console.log("service", e);
      }
    } else {
      console.log("Try With Consultant Who Has Rate");
      alert(t("USR.INSF.BAL.ALRT.MSG"));
      setMessageType("error");
    }
  };

  const handleChat = () => {
    const id = window.sessionStorage.getItem("consultantId");
    try {
      vasthuUrl
        .post(`/initiateChat`, {
          from: userId,
          to: id,
          lng: lang,
        })
        .then((res) => {
          console.log("chat after", res.status);
          navigate("/chatscreen");
        });
    } catch (e) {
      console.log("err", e);
    }
  };

  const CheckFreeStatus = (id, type) => {
    window.sessionStorage.removeItem("pendingAction");
    try {
      vasthuUrl
        .get(`/vasthu/user/getuserfreecallstatus/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const freeCallStatus = res.data.freeCallUsed;
          console.log("freeCallStatus", freeCallStatus);
          if (freeCallStatus) {
            setUsedFreeModal((prev) => prev + 1);
          } else {
            if (type == "freeCall") {
              handleFreeCall(id);
            } else if (type == "freeChat") {
              handleFreeChat(id);
            }
          }
        });
    } catch (e) {
      console.log("service", e);
    }
  };

  const handleFreeCall = (id) => {
    try {
      vasthuUrl
        .post(`/vasthu/user/callFromUserToConsultantfree`, {
          from: id,
          to: userId,
        })
        .then((res) => {
          if (res.status == 200) {
            alert(t("USR.CAL.PROCED.BTN"));
            setMessageType("success");
            setTimeout(() => {
              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.PROCED.BTN" },
              });
            }, 3000);
          } else {
            alert(t("USR.CAL.FAILD.BTN"));
            setMessageType("error");
            setTimeout(() => {
              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.FAILD.BTN" },
              });
            }, 3000);
          }
          window.sessionStorage.removeItem("pendingAction");
        });
    } catch (e) {
      console.log("err", e);
    }
  };

  const handleFreeChat = (id) => {
    try {
      vasthuUrl
        .post(`/initiateChat/makeFreeChat`, {
          from: userId,
          to: id,
          lng: lang,
        })
        .then((res) => {
          console.log("chat after", res.status);
          navigate("/chatscreen");
          window.sessionStorage.removeItem("pendingAction");
        });
    } catch (e) {
      console.log("err", e);
    }
  };

  const getConsutantServices = (uuid) => {
    try {
      vasthuUrl
        .get(`vasthu/getvasthuservicesbyconsultant/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          setConsultantServiceList(res.data);
          setSelectedConsultant(uuid);
          if (selectedConsultantService == null) {
            OpenServiceModal();
          } else {
            CheckConsultant(uuid);
          }
        });
    } catch (e) {
      console.log("service", e);
    }
  };

  const CheckConsultant = (uuid) => {
    window.sessionStorage.removeItem("pendingAction");
    if (typeof selectedConsultantService !== "undefined") {
      let value = profList.find(
        (x) => x.consultantUuid == (selectedConsultant || uuid)
      );
      window.sessionStorage.setItem(
        "consultantDetail",
        JSON.stringify({ value })
      );
      navigate("/customerpricing", { state: selectedConsultantService });
    } else {
      alert("Please Select a service");
      setMessageType("error");
    }
  };

  const payHandler = (txn) => {
    console.log("pay response out", txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => console.log("after txnsave", res.data))
            .catch((err) => console.log("after txnsave try", err));

          console.log("buying .. subsc");

          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: selectedAudioOffer,
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("call audio");
                console.log("afterbuysubscr", res.data);
                try {
                  vasthuUrl.post(
                    `vasthu/user/clicktocall`,
                    {
                      from: consultantId, //"09790580304",
                      to: uuid, //"07092758264",
                      duration: parseInt(duration), //30min
                      subscriptionId: res.data.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  console.log("postresponsefrom audio call", res.data);
                  console.log("postresponsefrom audio call", res);
                  if (res.status == 200) {
                    navigate("/audiocallstatus", {
                      state: { callStatus: "USR.CAL.PROCED.BTN" },
                    });
                    console.log(
                      "offerId - on 200 [already register offer] status",
                      res.data.id
                    );
                    vasthuUrl
                      .get(
                        // `/subscription/useVasthuOffer/${i.id}`,
                        `/subscription/useVasthuOffer/${res.data.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((rs) =>
                        console.log(
                          "offer subscription redeem count used",
                          rs.data.message
                        )
                      )
                      .catch((er) =>
                        console.log(
                          "offer subscription redeem count not reduced",
                          er
                        )
                      );
                  } else {
                    navigate("/audiocallstatus", {
                      state: { callStatus: "USR.CAL.FAILD.BTN" },
                    });
                  }
                } catch (err) {
                  console.log("SERVER ERROR", err);
                }
              });
          }, 1000);
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: selectedAudioOffer,
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
            });
        }
      });
  };

  const handleBuy1 = async (e) => {
    try {
      console.log("handle_buy1");
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: selectedAudioOffer,
            price: formData.price,
            currency: "356",
            returnUrl: "paymentstatusbilldesk",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("billDesk", res.data);
          BillDeskPay(res.data, payHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("payment initialize error", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePsumbit1 = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      vasthuUrl
        .get(`/subscription/userActiveOffers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (list) => {
          console.log("Audiocall list available " + JSON.stringify(list.data));
          let filteredAudioList = list.data.filter(
            // (obj) => obj.offerId == selectedAudioOffer
            (obj) => obj.offerId > 2
          );
          console.log("filtered audio list", filteredAudioList);
          console.log("filtered audio list len  ", filteredAudioList.length);

          console.log("filtered audio list", list.data);
          console.log("filtered audio list len  ", list.data.length);

          // setActiveServiceList(list.data);
          setActiveServiceList(filteredAudioList);

          // if ((list.data).length > 0) {
          // if (filteredAudioList.length > 0) {
          //   openModal1();
          if (false) {
          } else {
            console.log("no subscription - please proceed with pay");
            setLoading(false);
            handleBuy1();
          }
        });
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      setMessageType("error");
      console.log("error from trying subscription list " + e);
      setLoading(false);
    }
  };

  const handleDurationRadio = (e) => {
    if (e.target.value === "15") {
      window.sessionStorage.setItem("AudioCallDuration", String(900));
      setSelectedAudioOffer(defaultAudioOffer15.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer15.offerPrice,
      });
    } else if (e.target.value === "30") {
      window.sessionStorage.setItem("AudioCallDuration", String(1800));
      setSelectedAudioOffer(defaultAudioOffer30.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer30.offerPrice,
      });
    } else {
      window.sessionStorage.setItem("AudioCallDuration", String(3600));
      setSelectedAudioOffer(defaultAudioOffer.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer.offerPrice,
      });
    }
  };

  const ConsultaionReview = () => {
    try {
      vasthuUrl
        .get(`usersreviewforconsultant/${conUuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("user reviews", res.data);
          setUserReview(res.data);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
    try {
      vasthuUrl
        .get(`usersreviewcountforconsultant/${conUuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantStats(res.data);
          console.log("user count", res.data);
          if (res.data) {
            setFiveStars(
              (res.data.rating5count / res.data.total_review_count) * 100
            );
            setFourStars(
              (res.data.rating4count / res.data.total_review_count) * 100
            );
            setThreeStars(
              (res.data.rating3count / res.data.total_review_count) * 100
            );
            setTwoStars(
              (res.data.rating2count / res.data.total_review_count) * 100
            );
            setOneStar(
              (res.data.rating1count / res.data.total_review_count) * 100
            );
          }
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  };

  useEffect(() => {
    ConsultaionReview();
  }, [reviewDesc]);

  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [showCountDownModal, setShowCountDownModal] = useState(false);

  useEffect(() => {
    if (isCounting && count > 0) {
      const timer = setInterval(() => {
        setCount((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (count === 0) {
      setIsCounting(false);
    }
  }, [isCounting, count]);

  const startCountdown = () => {
    setCount(20);
    setIsCounting(true);
    setShowCountDownModal(true);
    setTimeout(() => {
      handleChat();
    }, 20000);
  };

  const closeModals = () => {
    setShowCountDownModal(false);
    setIsCounting(false);
  };

  return (
    <div>
      <ToastMsg msg={message} type={messageType} trigger={toastTrigger} />
      <FreeUsed usedFreeModal={usedFreeModal} walletBalance={walletBalance} />

      <Modal2
        show={modalIsOpen}
        backdrop="static"
        keyboard={false}
        onHide={closeModal}
        className="all_border"
        centered
      >
        <Modal2.Header closeButton className="all_background">
          <h3>Audio Call Duration</h3>
        </Modal2.Header>
        <h3
          className="text-center mt-3"
          ref={(_subtitle) => (subtitle = _subtitle)}
        >
          {t("TXT.SEL.AUD.CAL.DURA.LO")}
        </h3>
        <form onSubmit={handlePsumbit1}>
          <div
            className="text-center"
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <div>
              <label>15 {t("TXT.MIN.LO")}</label>
              {/*  15 min */}
              <input
                type="radio"
                name="duration"
                value={15}
                onChange={handleDurationRadio}
                style={{ marginLeft: "revert" }}
              />
            </div>
            <div>
              <label>30 {t("TXT.MIN.LO")}</label>
              {/*  30 min */}
              <input
                type="radio"
                name="duration"
                value={30}
                onChange={handleDurationRadio}
                style={{ marginLeft: "revert" }}
              />
            </div>
            {/* <input type="submit" /> */}
          </div>
          <Modal2.Footer
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "5px",
            }}
            className="all_border"
          >
            <Button size="sm" type="submit" className="all_btn">
              {t("USR.VFP.DRP.BTN.SMT")}
            </Button>
            <Button size="sm" onClick={closeModal} className="all_btn">
              {t("TXT.BTN.CAN.LO")}
            </Button>
          </Modal2.Footer>
        </form>
      </Modal2>

      <Modal
        isOpen={serviceModal}
        onAfterOpen={afterServiceModal}
        onRequestClose={closeServiceModal}
        // style={customStyles}
        contentLabel="Select Consultaion Service"
      >
        <h2
          className="text-center all_background"
          ref={(_subtitle) => (subtitle = _subtitle)}
        >
          {t("TXT.SEL.SER.LO")}
        </h2>

        <div
          className="text-center"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <FloatingLabel controlId="min">
            <Form.Select
              aria-label="min"
              size="sm"
              className="form_feild"
              onChange={(e) => {
                console.log("SelectedConsultationService", e.target.value);
                setSelectedConsultantService(e.target.value);
              }}
            >
              <option>{t("TXT.SEL.SER.LO")}</option>
              {consultantServiceList.map((service, index) => (
                <option key={index} value={service.id}>
                  {lang == "en"
                    ? service.serviceName
                    : lang == "ta"
                    ? service.serviceNameTamil
                    : service.serviceNameHindi}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          <Button
            size="sm"
            type="submit"
            className="all_btn"
            style={{ width: "100%", marginTop: "8px" }}
            onClick={CheckConsultant}
          >
            {t("USR.VFP.DRP.BTN.SMT")}
          </Button>
        </div>
        <center>
          <Button
            size="sm"
            style={{ width: "100%", marginTop: "8px" }}
            onClick={closeServiceModal}
            className="all_btn"
          >
            {t("TXT.BTN.CAN.LO")}
          </Button>
        </center>
      </Modal>

      <Modal3
        show={model1}
        backdrop="static"
        keyboard={false}
        onHide={modelClose1}
        className="all_border"
      >
        <Modal3.Header closeButton className="all_background">
          <h3>Consultant Review Details</h3>
        </Modal3.Header>
        <Modal3.Body>
          <div>Are you sure want Consultant Review Details?</div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "5%" }}
          >
            <Button className="all_btn" onClick={() => modelClose1()}>
              No
            </Button>
            <Button className="all_btn" onClick={() => modelOpen()}>
              Yes
            </Button>
          </div>
        </Modal3.Body>
      </Modal3>

      <Modal2
        show={model}
        backdrop="static"
        keyboard={false}
        onHide={modelClose}
        fullscreen={true}
        className="all_border"
      >
        <Modal2.Header closeButton className="all_background">
          <h3>Consultant Review Details</h3>
        </Modal2.Header>

        <Card className="all_border mx-5 p-2 mt-3">
          <center>
            {" "}
            <h6>{t("USR.BOK.HIS.RAT")}</h6>
            <div
            // style={{ display: "flex", justifyContent: "center" }}
            >
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    style={{
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      border: "none",
                      position: "relative",
                    }}
                    key={index}
                    className={index <= rating ? "on" : "off"}
                    onClick={() => {
                      console.log("STAR RATING", index);
                      setRating(index);
                    }}
                  >
                    <span
                      className="star"
                      style={{ fontSize: "30px", textAlign: "center" }}
                    >
                      &#9733;
                    </span>
                  </button>
                );
              })}
            </div>
          </center>

          <div className="d-flex justify-content-center g-4">
            {" "}
            <Form.Control
              as="textarea"
              className="all_border mb-2"
              rows={2}
              size="sm"
              maxLength={250}
              onChange={(e) => setReviewDesc(e.target.value)}
              placeholder="Enter your review"
              value={reviewDesc}
            />
            <Button className="all_btn mb-2" onClick={handleSendReview}>
              {t("USR.HOM.BTN.SUB")}
            </Button>
          </div>
        </Card>

        <Modal2.Body>
          <>
            <Container>
              <Card className="text-center all_border mt-1">
                {consultantStats && (
                  <>
                    <Card.Header className="d-flex all_background justify-content-center align-items-center ">
                      {conImgUrl ? (
                        <Image
                          fluid
                          thumbnail
                          roundedCircle
                          style={{ width: 60, height: 60, margin: 5 }}
                          src={conImgUrl}
                          alt="profile"
                        />
                      ) : (
                        <Image
                          fluid
                          thumbnail
                          roundedCircle
                          style={{ width: 60, height: 60, margin: 5 }}
                          src={require("../images/noimage.jpg")}
                          alt="profile"
                        />
                      )}
                      <h5 className="pt-2">
                        {conName} <br />
                        <Badge bg="danger" pill>
                          {consultantStats.average_rating}/5.0
                        </Badge>{" "}
                        ({consultantStats.total_review_count})
                      </h5>
                    </Card.Header>
                    <Card.Body>
                      <ListGroup style={{ border: "1px solid #ffb33e" }}>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center "
                        >
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            5 - {t("PR.AC.RVWTAB.STRS.CMM")}
                          </h6>
                          <ProgressBar
                            className="d-flex"
                            style={{ width: "70%" }}
                            variant="success"
                            now={fiveStars}
                          />
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            {consultantStats.rating5count}
                          </h6>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center "
                        >
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            4 - {t("PR.AC.RVWTAB.STRS.CMM")}
                          </h6>
                          <ProgressBar
                            style={{ width: "70%" }}
                            variant="info"
                            now={fourStars}
                          />
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            {consultantStats.rating4count}
                          </h6>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center "
                        >
                          {" "}
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            3 - {t("PR.AC.RVWTAB.STRS.CMM")}
                          </h6>
                          <ProgressBar
                            style={{ width: "70%" }}
                            variant="warning"
                            now={threeStars}
                          />
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            {consultantStats.rating3count}
                          </h6>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center "
                        >
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            2 - {t("PR.AC.RVWTAB.STRS.CMM")}
                          </h6>
                          <ProgressBar
                            style={{ width: "70%" }}
                            variant="danger"
                            now={twoStars}
                          />
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            {consultantStats.rating2count}{" "}
                          </h6>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center "
                        >
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            1 - {t("PR.AC.RVWTAB.STR.CMM")}
                          </h6>
                          <ProgressBar
                            style={{ width: "70%" }}
                            variant="primary"
                            now={oneStar}
                          />
                          <h6 className="mt-2" style={{ color: "grey" }}>
                            {consultantStats.rating1count}
                          </h6>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </>
                )}
              </Card>
              {userReview.length > 0 ? (
                <Card className="mt-2">
                  <Card.Header className="text-center" as="h3">
                    {t("PR.AC.RVWTAB")}
                  </Card.Header>
                  {userReview.map((i, j) => (
                    <Card.Body key={j}>
                      <div className="review-list">
                        <ul>
                          <li>
                            <div className="d-flex">
                              <div className="left">
                                <span>
                                  <img
                                    src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                    className="profile-pict-img img-fluid"
                                    alt="userProfile"
                                  />
                                </span>
                              </div>
                              <div className="right">
                                <h4>
                                  {i.user.userName}
                                  <span className="gig-rating text-body-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 1792 1792"
                                      width="15"
                                      height="15"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                                      ></path>
                                    </svg>
                                    {i.user_rating}
                                  </span>
                                </h4>
                                <div className="country d-flex align-items-center">
                                  <div className="country-name font-accent">
                                    {i.user.email}
                                  </div>
                                </div>
                                <div className="review-description">
                                  <p>{i.review_description}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Card.Body>
                  ))}
                </Card>
              ) : (
                <center>
                  <h5 className="mt-3">{t("PR.AC.RVWTAB.MSG.NORVW")}</h5>
                </center>
              )}
            </Container>
            <Button
              className="all_btn mt-2"
              style={{
                float: "right",
              }}
              onClick={() => modelClose()}
            >
              {t("TXT.CLO.LO")}
            </Button>
          </>
        </Modal2.Body>
      </Modal2>

      <Toast
        show={toast}
        backdrop="static"
        keyboard={false}
        onHide={() => setToast(false)}
        className="all_border"
      >
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          Hello! <b>{consulName}</b> is currenty busy on another call, please
          bear with us for {waitingTime} minutes{" "}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            {t("TXT.CLO.LO")}
          </Button>
        </Toast.Footer>
      </Toast>

      <Modal2
        show={checkBookStatus}
        backdrop="static"
        keyboard={false}
        onHide={closeCheckBookStatus}
      >
        <Modal2.Header className="all_background" closeButton>
          <Modal2.Title>{t("USR.BSY.ALT")}</Modal2.Title>
        </Modal2.Header>
        <Modal2.Body>
          {/* <b>{consulName}</b>&nbsp; */}
          {bookingStatus.status == "DONT CALL" ? (
            <div>{t("USR.DONT.CL.MSG")}</div>
          ) : (
            <div>
              {t("USR.THIS.CON.BUSY.TEXT")}{" "}
              <b>{bookingStatus.allowedTalkTime}</b> {t("USR.CONS.CALCOMG.MSG")}
            </div>
          )}
        </Modal2.Body>
        <Modal2.Footer className="d-flex justify-content-evenly">
          <Button className="all_btn1" onClick={closeCheckBookStatus}>
            {t("TXT.CLO.LO")}
          </Button>
          {bookingStatus.status == "DONT CALL" ? null : (
            <Button className="all_btn1" onClick={() => handleProceed()}>
              {t("USR.BOK.UR.PLN.PRCED")}
            </Button>
          )}
        </Modal2.Footer>
      </Modal2>

      <Modal2
        show={callType}
        backdrop="static"
        keyboard={false}
        onHide={closeCallType}
        className="all_border"
        centered
      >
        <Modal2.Header closeButton className="all_background">
          <h3>Call Type</h3>
        </Modal2.Header>
        <Row>
          <Col xs={2} sm={2} md={3}></Col>
          <Col xs={8} sm={8} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <div>
                <input
                  type="radio"
                  name="callType"
                  value="wallet"
                  onChange={(e) => handleProceedCall(e.target.value)}
                  style={{ marginRight: "5px" }}
                />
                <label>{t("USR.MIN.BASED")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="callType"
                  value="duration"
                  onChange={(e) => handleProceedCall(e.target.value)}
                  style={{ marginRight: "5px" }}
                />
                <label>{t("USR.DUR.BASED")}</label>
              </div>
            </div>
          </Col>
          <Col xs={2} sm={2} md={3}></Col>
        </Row>
        <Modal2.Footer className="d-flex justify-content-evenly">
          <Button className="all_btn1" onClick={closeCallType}>
            {t("TXT.CLO.LO")}
          </Button>
        </Modal2.Footer>
      </Modal2>

      <Modal2
        show={chatUserDetailsModel}
        backdrop="static"
        keyboard={false}
        onHide={handleUserDetailesCloseModel}
        className="all_border"
        centered
        fullscreen
      >
        <Modal2.Header
          handleUserDetailesCloseModel
          closeButton
          className="all_background"
        ></Modal2.Header>
        <Modal2.Body>
          <Container>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor:
                      selectedOption === "Personal" ? "#FFC33B" : "#fff",
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  onClick={() => setSelectedOption("Personal")}
                >
                  <input
                    type="radio"
                    name="PaymentOption"
                    value="Personal"
                    checked={selectedOption === "Personal"}
                    style={{ marginRight: "5px" }}
                    onChange={() => setSelectedOption("Personal")}
                  />
                  <label>{t("USR.PRSNL.CONSTATION")}</label>
                </div>

                <div
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor:
                      selectedOption === "Matching" ? "#FFC33B" : "#fff",
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  onClick={() => setSelectedOption("Matching")}
                >
                  <input
                    type="radio"
                    name="PaymentOption"
                    value="Matching"
                    checked={selectedOption === "Matching"}
                    style={{ marginRight: "5px" }}
                    onChange={() => setSelectedOption("Matching")}
                  />
                  <label style={{ display: "flex", alignItems: "center" }}>
                    {t("USER.MATCHING")}
                  </label>
                </div>
              </div>

              {selectedOption == "Personal" ? (
                <>
                  {/* {savePersonalDetailes && savePersonalDetailes.length > 0 ? ( */}
                  {savePersonalDetailes ? (
                    <>
                      <center style={{ color: "red" }}>
                        <h4>{t("USR.CHAT.TONE.ALT.MSG")}</h4>
                      </center>
                      <Row>
                        {/* {savePersonalDetailes.map((i, j) => ( */}
                        <Col
                          // key={j}
                          className="d-flex flex-row"
                          sm={12}
                          md={8}
                          lg={6}
                        >
                          <input
                            type="radio"
                            name="singleSelection"
                            style={{ marginRight: "5px" }}
                            onChange={() => {
                              setSingleForm(false);
                              setSingleSelectedCard(savePersonalDetailes);
                              handleSelection(savePersonalDetailes, "single");
                            }}
                            checked={
                              singleSelectedCard &&
                              singleSelectedCard.name ===
                                savePersonalDetailes.userName
                            }
                          />
                          <Card
                            className="all_border"
                            style={{
                              width: "100%",
                              marginBottom: "10px",
                              backgroundColor: "antiquewhite",
                            }}
                          >
                            <Card.Body>
                              <Card.Title>
                                {t("TXT.NM.LO")}:{" "}
                                {savePersonalDetailes.userName}
                              </Card.Title>
                              <Card.Title>
                                {t("USR.DOB.SR")}:{" "}
                                {formatDate(
                                  new Date(savePersonalDetailes.birthDate)
                                )}
                              </Card.Title>
                              <Card.Title>
                                {t("USR.CON.SCH.USR.BIR.TIM")}:{" "}
                                {formatTime(savePersonalDetailes.birthTime)}
                              </Card.Title>
                              <Card.Title>
                                {t("USR.CON.SCH.USR.BIR.PLA")}:{" "}
                                {savePersonalDetailes.userBirthstate}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* ))} */}

                        <div className="d-flex justify-content-end">
                          {!singleForm && (
                            <Button
                              className="all_btn"
                              onClick={() => {
                                console.log("Button Clicked!");
                                setSingleSelectedCard(null);
                                setSingleForm(true);
                                setUserName("");
                                setDateValue(null);
                                setTimeValue(null);
                                setUserPlace("");
                                console.log("Updated State:", {
                                  singleSelectedCard,
                                  singleForm,
                                  userName,
                                  dateValue,
                                  timeValue,
                                  userPlace,
                                });
                              }}
                            >
                              {t("USR.ADD.NEW.BTN")}
                            </Button>
                          )}
                        </div>

                        {singleSelectedCard && (
                          <React.Fragment>
                            <Col sm={12} md={8} lg={6}>
                              <Card
                                style={{
                                  border: "2px solid green",
                                  marginTop: "20px",
                                  padding: "5px",
                                  backgroundColor: "#daefda",
                                }}
                              >
                                <h4>{t("USR.DETAILES")}:</h4>
                                <p>
                                  <strong>{t("TXT.NM.LO")}:</strong>{" "}
                                  {singleSelectedCard.name}
                                </p>
                                <p>
                                  <strong>{t("USR.DOB.SR")}:</strong>{" "}
                                  {singleSelectedCard.birthDate}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.TIM")}:
                                  </strong>{" "}
                                  {singleSelectedCard.birthTime}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.PLA")}:
                                  </strong>{" "}
                                  {singleSelectedCard.birthPlace}
                                </p>
                              </Card>
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>
                    </>
                  ) : null}

                  {/* //personal add form  */}
                  {singleForm && (
                    <Row className="d-flex justify-content-center all_border">
                      <Col sm={12} md={8} lg={4} className="mt-3">
                        <Card className="all_border">
                          <Card.Header className="all_background" as="h5">
                            <center>{t("USR.PRSNL.CONSTATION")}</center>
                          </Card.Header>
                          <Card.Body>
                            <Form className="mt-2">
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("TXT.NM.LO")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={userName}
                                  required
                                  className="form_feild"
                                  placeholder={t("USR.VSH.HFTAB.HO.PH")}
                                  onChange={(e) => {
                                    let input = e.target.value;
                                    input = input.replace(/^([^A-Za-z])/, "");
                                    input = input.replace(/[^A-Za-z0-9]/g, "");
                                    console.log(input);
                                    setUserName(input);
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.DAT")}
                                </Form.Label>
                                <br />
                                <div className="datePicker_booking">
                                  <DatePicker
                                    value={dateValue}
                                    maxDate={new Date()}
                                    onChange={handleDateChange}
                                    format="dd-MM-yy"
                                    className="form_feild"
                                    style={{ backgroundColor: "#ffb33e" }}
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.TIM")}
                                </Form.Label>
                                <br />
                                <TimePicker
                                  className="form_feild"
                                  value={
                                    timeValue
                                      ? dayjs(timeValue, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) =>
                                    handleTimeChange("general", time)
                                  }
                                  disabledTime={() => ({
                                    disabledHours: getDisabledHours,
                                    disabledMinutes: getDisabledMinutes,
                                  })}
                                  format="hh:mm A"
                                  use12Hours
                                  showNow={false}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  style={{
                                    width: "100%",
                                    height: "38px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.PLA")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={userPlace}
                                  className="form_feild"
                                  placeholder={t("USR.ENTER.BRTH.PLC")}
                                  onChange={(e) => {
                                    let input = e.target.value;
                                    input = input.replace(/^([^A-Za-z])/, "");
                                    input = input.replace(/[^A-Za-z0-9]/g, "");
                                    console.log(input);
                                    setUserPlace(input);
                                  }}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {singleSelectedCard || singleForm ? (
                    <Row className="d-flex mt-4">
                      <Col className="d-flex justify-content-end">
                        <Button
                          className="all_btn"
                          onClick={() => {
                            handleButtonClick(false);
                          }}
                          disabled={
                            !singleSelectedCard &&
                            (!userName ||
                              !dateValue ||
                              !timeValue ||
                              !userPlace)
                          }
                        >
                          {t("USR.HOM.BTN.SUB")}
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : (
                <>
                  {saveMatchingDetailes ? (
                    <>
                      <Row className="d-flex justify-content-center all_border"></Row>
                      <>
                        <center style={{ color: "red" }}>
                          <h4>{t("USR.MATC.ALT.MSG")}</h4>
                        </center>
                        <Row className="d-flex flex-row justify-content-center all_border">
                          {/* {saveMatchingDetailes.map((i, j) => ( */}
                          <>
                            <Col
                              // className="d-flex flex-row p-0"
                              xs={6}
                              // sm={12}
                              // md={8}
                              // lg={6}
                            >
                              <center>{t("USR.MAT.GROOM")}</center>
                              <div className="d-flex flex-row p-0">
                                <input
                                  type="radio"
                                  name="maleSelection"
                                  style={{ marginRight: "5px" }}
                                  onChange={() => {
                                    setShowForm(false);
                                    handleSelection(
                                      saveMatchingDetailes,
                                      "male"
                                    );
                                  }}
                                  checked={
                                    maleselectedCard &&
                                    maleselectedCard.name ===
                                      saveMatchingDetailes.maleUserName
                                  }
                                />
                                <Card
                                  // key={j}
                                  style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    backgroundColor: "antiquewhite",
                                  }}
                                  className="all_border"
                                >
                                  <Card.Body>
                                    <Card.Title className="detail_card_title">
                                      {t("TXT.NM.LO")}:{" "}
                                      {saveMatchingDetailes.maleUserName}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.DOB.SR")}:{" "}
                                      {formatDate(
                                        new Date(
                                          saveMatchingDetailes.maleBirthDate
                                        )
                                      )}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.CON.SCH.USR.BIR.TIM")}:{" "}
                                      {formatTime(
                                        saveMatchingDetailes.maleBirthTime
                                      )}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.CON.SCH.USR.BIR.PLA")}:{" "}
                                      {saveMatchingDetailes.maleBirthPlace}
                                    </Card.Title>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                            <Col
                              // className="d-flex flex-row p-0"
                              xs={6}
                              // sm={12}
                              // md={8}
                              // lg={6}
                            >
                              <center>{t("USR.ADD.BRIDE")}</center>
                              <div className="d-flex flex-row p-0">
                                <input
                                  type="radio"
                                  name="femaleSelection"
                                  style={{ marginRight: "5px" }}
                                  onChange={() => {
                                    handleSelection(
                                      saveMatchingDetailes,
                                      "female"
                                    );
                                    setShowFemaleForm(false);
                                  }}
                                  checked={
                                    femaleSelectedCard &&
                                    femaleSelectedCard.name ===
                                      saveMatchingDetailes.femaleUserName
                                  }
                                />
                                <Card
                                  style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    backgroundColor: "antiquewhite",
                                  }}
                                  className="all_border"
                                >
                                  <Card.Body>
                                    <Card.Title className="detail_card_title">
                                      {t("TXT.NM.LO")}:{" "}
                                      {saveMatchingDetailes.femaleUserName}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.DOB.SR")}:{" "}
                                      {formatDate(
                                        new Date(
                                          saveMatchingDetailes.femaleBirthDate
                                        )
                                      )}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.CON.SCH.USR.BIR.TIM")}:{" "}
                                      {formatTime(
                                        saveMatchingDetailes.femaleBirthTime
                                      )}
                                    </Card.Title>
                                    <Card.Title className="detail_card_title">
                                      {t("USR.CON.SCH.USR.BIR.PLA")}:{" "}
                                      {saveMatchingDetailes.femaleBirthPlace}
                                    </Card.Title>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          </>
                          {/* ))} */}

                          {/* Add male & Add female button */}
                          <div
                            className="d-flex flex-row justify-content-around"
                            style={{ gap: "5px" }}
                          >
                            {!showForm && (
                              <Button
                                className="all_btn detail_card_title"
                                onClick={() => {
                                  //  setMaleSelectedCard(null);
                                  setMaleSelectedCard((prev) =>
                                    prev !== null ? null : prev
                                  );
                                  setShowForm(true);
                                  console.log("Button Clicked!");
                                  setMaleUserName("");
                                  setMaleDateValue(null);
                                  setMaleTimeValue(null);
                                  setMaleUserPlace("");
                                  setShowAddMaleButton(false);
                                  console.log("Updated State:", {
                                    maleselectedCard,
                                    showForm,
                                    maleUserName,
                                    maledateValue,
                                    maletimeValue,
                                    maleuserPlace,
                                  });
                                }}
                              >
                                {/* Add Male */}
                                <FaMale className="detail_card_title" />
                                {t("USR.MAT.GROOM")}
                              </Button>
                            )}

                            {!showFemaleForm && (
                              <Button
                                className="all_btn detail_card_title"
                                onClick={() => {
                                  setFemaleSelectedCard(null);
                                  setShowFemaleForm(true);
                                  console.log("Button Clicked!");
                                  setFeUserName("");
                                  setFeDateValue(null);
                                  setFeTimeValue(null);
                                  setFeUserPlace("");
                                  setShowAddFemaleButton(false);
                                  console.log("Updated State:", {
                                    femaleSelectedCard,
                                    showFemaleForm,
                                    feuserName,
                                    fedateValue,
                                    fetimeValue,
                                    feuserPlace,
                                  });
                                }}
                              >
                                {/* Add Female */}
                                <FaFemale className="detail_card_title" />
                                {t("USR.ADD.BRIDE")}
                              </Button>
                            )}
                          </div>

                          {maleselectedCard && (
                            <Col sm={12} md={8} lg={6}>
                              <Card
                                style={{
                                  border: "2px solid green",
                                  marginTop: "20px",
                                  padding: "5px",
                                  backgroundColor: "#daefda",
                                }}
                              >
                                <h4>{t("USR.GROOM.DETAILES")}:</h4>
                                <p>
                                  <strong>{t("TXT.NM.LO")}:</strong>{" "}
                                  {maleselectedCard.name}
                                </p>
                                <p>
                                  <strong>{t("USR.DOB.SR")}:</strong>{" "}
                                  {maleselectedCard.birthDate}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.TIM")}:
                                  </strong>{" "}
                                  {maleselectedCard.birthTime}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.PLA")}:
                                  </strong>{" "}
                                  {maleselectedCard.birthPlace}
                                </p>
                              </Card>
                            </Col>
                          )}

                          {femaleSelectedCard && (
                            <Col sm={12} md={8} lg={6}>
                              <Card
                                style={{
                                  border: "2px solid green",
                                  marginTop: "20px",
                                  padding: "5px",
                                  backgroundColor: "#daefda",
                                }}
                              >
                                <h4>{t("USR.BRIDE.DETAILES")}:</h4>
                                <p>
                                  <strong>{t("TXT.NM.LO")}:</strong>{" "}
                                  {femaleSelectedCard.name}
                                </p>
                                <p>
                                  <strong>{t("USR.DOB.SR")}:</strong>{" "}
                                  {femaleSelectedCard.birthDate}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.TIM")}:
                                  </strong>{" "}
                                  {femaleSelectedCard.birthTime}
                                </p>
                                <p>
                                  <strong>
                                    {t("USR.CON.SCH.USR.BIR.PLA")}:
                                  </strong>{" "}
                                  {femaleSelectedCard.birthPlace}
                                </p>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      </>
                    </>
                  ) : null}

                  <span className="d-flex justify-content-around p-3">
                    {showForm && (
                      <Col sm={12} md={8} lg={4} className="mt-3">
                        <Card className="all_border">
                          <Card.Header className="all_background" as="h5">
                            <center>{t("USR.GROOM")}</center>
                          </Card.Header>
                          <Card.Body>
                            <Form className="mt-2">
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("TXT.NM.LO")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={maleUserName}
                                  required
                                  className="form_feild"
                                  placeholder={t("USR.VSH.HFTAB.HO.PH")}
                                  onChange={(e) => {
                                    let input = e.target.value.replace(
                                      /[^A-Za-z0-9]/g,
                                      ""
                                    );
                                    setMaleUserName(input);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.GENDER")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    gender ? t("USR.MALE") : t("USR.FEMALE")
                                  }
                                  className="form_feild"
                                  disabled
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.DAT")}
                                </Form.Label>
                                <br />
                                <div className="datePicker_booking">
                                  <DatePicker
                                    value={maledateValue}
                                    maxDate={new Date()}
                                    onChange={handleMaleDateChange}
                                    format="dd-MM-yy"
                                    className="form_feild"
                                    style={{ backgroundColor: "#ffb33e" }}
                                  />
                                </div>
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.TIM")}
                                </Form.Label>
                                <br />
                                <TimePicker
                                  value={
                                    maletimeValue
                                      ? dayjs(maletimeValue, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) =>
                                    handleTimeChange("male", time)
                                  }
                                  disabledTime={() => ({
                                    disabledHours: getDisabledHours,
                                    disabledMinutes: getDisabledMinutes,
                                  })}
                                  format="hh:mm A"
                                  use12Hours
                                  showNow={false}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  status="warning"
                                  style={{
                                    width: "100%",
                                    height: "38px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.PLA")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={maleuserPlace}
                                  className="form_feild"
                                  placeholder={t("USR.ENTER.BRTH.PLC")}
                                  onChange={(e) => {
                                    let input = e.target.value.replace(
                                      /[^A-Za-z0-9]/g,
                                      ""
                                    );
                                    setMaleUserPlace(input);
                                  }}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {/* Female add Form */}
                    {showFemaleForm && (
                      <Col sm={12} md={8} lg={4} className="mt-3">
                        <Card className="all_border">
                          <Card.Header className="all_background" as="h5">
                            <center>{t("USR.BRIDE")}</center>
                          </Card.Header>
                          <Card.Body>
                            <Form className="mt-2">
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("TXT.NM.LO")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={feuserName}
                                  required
                                  className="form_feild"
                                  placeholder={t("USR.VSH.HFTAB.HO.PH")}
                                  onChange={(e) => {
                                    let input = e.target.value;
                                    input = input.replace(/^([^A-Za-z])/, "");
                                    input = input.replace(/[^A-Za-z0-9]/g, "");
                                    console.log(input);
                                    setFeUserName(input);
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.GENDER")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    !gender ? t("USR.MALE") : t("USR.FEMALE")
                                  }
                                  className="form_feild"
                                  disabled
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.DAT")}
                                </Form.Label>
                                <br />
                                <div className="datePicker_booking">
                                  <DatePicker
                                    value={fedateValue}
                                    maxDate={new Date()}
                                    onChange={handleFemaleDateChange}
                                    format="dd-MM-yy"
                                    className="form_feild"
                                    style={{ backgroundColor: "#ffb33e" }}
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.TIM")}
                                </Form.Label>
                                <br />
                                <TimePicker
                                  value={
                                    fetimeValue
                                      ? dayjs(fetimeValue, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) =>
                                    handleTimeChange("female", time)
                                  }
                                  format="hh:mm A"
                                  use12Hours
                                  showNow={false}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  disabledTime={() => ({
                                    disabledHours: getDisabledHours,
                                    disabledMinutes: getDisabledMinutes,
                                  })}
                                  status="warning"
                                  style={{
                                    width: "100%",
                                    height: "38px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2"
                                style={{ textAlign: "start" }}
                              >
                                <Form.Label
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("USR.CON.SCH.USR.BIR.PLA")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={feuserPlace}
                                  className="form_feild"
                                  placeholder={t("USR.ENTER.BRTH.PLC")}
                                  onChange={(e) => {
                                    let input = e.target.value;
                                    input = input.replace(/^([^A-Za-z])/, "");
                                    input = input.replace(/[^A-Za-z0-9]/g, "");
                                    console.log(input);
                                    setFeUserPlace(input);
                                  }}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </span>

                  {(maleselectedCard || showForm) &&
                  (femaleSelectedCard || showFemaleForm) ? (
                    <div className="d-flex justify-content-end">
                      <Button
                        className="all_btn1"
                        onClick={() => {
                          console.log("Submit okkk button clicked");
                          handleAddMatchingDetailes(false);
                        }}
                      >
                        {t("USR.HOM.BTN.SUB")}
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
            </Row>
          </Container>
        </Modal2.Body>
      </Modal2>

      <Modal2
        show={showCountDownModal}
        backdrop="static"
        keyboard={false}
        onHide={closeModals}
        className="all_border"
        centered
      >
        <Row>
          <Col xs={12} sm={12} md={12} className="text-center">
            <h2 className="countdown_tone_text p-4">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  width: "100px",
                  margin: "auto",
                }}
              >
                <Spinner
                  animation="border"
                  style={{ width: "4rem", height: "4rem", color: "#239414" }}
                />
              </div>
              <center>Connecting in {count} seconds...</center>
            </h2>
          </Col>
        </Row>
      </Modal2>

      <Row className="d-flex  g-1">
        {filteredPersons.length > 0 ? (
          <>
            {filteredPersons.map((i, j) => (
              <>
                {i.professionalAccountStatus == "ACTIVATE" ? (
                  <Col md={6} lg={4}>
                    <Card
                      className="d-flex professionalCard all_border"
                      key={i.consultantUuid}
                    >
                      <Card.Header className="all_border">
                        <div className="d-flex justify-content-between flex-row">
                          <strong>{i.consultantName}</strong>
                          <span style={{ width: "max-content" }}>
                            <Badge
                              pill
                              bg="warning"
                              text="dark"
                              size="sm"
                              onClick={() => {
                                if (token) {
                                  modelOpen1();
                                  setConUuid(i.consultantUuid);
                                  setConName(i.consultantName);
                                  setConImgUrl(i.imgUrl);
                                } else {
                                  navigate("/login");
                                  window.sessionStorage.setItem(
                                    "rerender",
                                    location.pathname
                                  );
                                }
                              }}
                            >
                              <AiOutlineStar size={15} />
                              {i.consultantRating}
                            </Badge>
                          </span>
                        </div>
                        <div
                          className="mt-2 gap-1"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div>
                            {i.imgUrl ? (
                              <>
                                <img
                                  src={i.imgUrl}
                                  style={{
                                    borderRadius: "50%",
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={require("../images/noimage.jpg")}
                                  style={{
                                    borderRadius: "50%",
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </>
                            )}
                            <div className="mt-2">
                              <b>
                                <span>₹{i.ratePerMin}</span>&nbsp;
                              </b>
                              <del style={{ color: "red" }}>
                                {i.maxRate == 0
                                  ? null
                                  : `${i.maxRate}/${t("TXT.MIN.LO")}`}
                              </del>
                            </div>
                          </div>

                          <div
                            sm={9}
                            style={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <small
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <MdOutlineLanguage />
                              {i.knownLangugae},{i.extraLang}
                            </small>
                            <small
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <BiBadgeCheck />
                              {i.skills},{i.extraSkill}
                            </small>
                            <small>
                              <GrUserExpert />
                              {i.expYear}
                              {t("USR.HOM.CL.TEXT1")}
                            </small>
                            {i.profstatus === 1 &&
                            !freeCallStatus &&
                            i.freeCallMins > 0 &&
                            i.freeCallAccess ? (
                              <small
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                <span> {t("USR.FREE")} </span>

                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      <span>
                                        {i.freeCallMins}&nbsp;
                                        {t("USR.MIN.FREE")}{" "}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <u
                                    style={{
                                      margin: "0px 5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      checkLoginAndGetConsultant(
                                        i.consultantUuid,
                                        i.consultantName,
                                        "freeCall",
                                        i.ratePerMin
                                      );
                                    }}
                                  >
                                    {t("TXT.CAL.LO")}{" "}
                                  </u>
                                </OverlayTrigger>
                                <span style={{ margin: "0px 5px" }}>/</span>
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      <span>
                                        {i.freeCallMins}&nbsp;
                                        {t("USR.MIN.FREE")}{" "}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      checkLoginAndGetConsultant(
                                        i.consultantUuid,
                                        i.consultantName,
                                        "freeChat",
                                        i.ratePerMin
                                      );
                                    }}
                                  >
                                    {t("USR.CHAT.BTN")}
                                  </u>
                                </OverlayTrigger>
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="text-center">
                        <Row>
                          <Col className="d-flex justify-content-evenly">
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {t("TXT.STA.CALL.LO")}
                                </Tooltip>
                              }
                            >
                              <Button
                                className="mt-2 all_btn"
                                size="sm"
                                disabled={i.profstatus === 1 ? false : true}
                                onClick={() => {
                                  checkLoginAndGetConsultant(
                                    i.consultantUuid,
                                    i.consultantName,
                                    "Call",
                                    i.ratePerMin
                                  );
                                }}
                              >
                                {t("USR.HOM.CL.ACTION1")}
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {t("TXT.DO.SCH.LO")}
                                </Tooltip>
                              }
                            >
                              <Button
                                className="mt-2 all_btn"
                                size="sm"
                                onClick={() => {
                                  checkLoginAndGetConsultant(
                                    i.consultantUuid,
                                    i.consultantName,
                                    "Book"
                                  );
                                }}
                              >
                                {t("USR.HOM.CL.ACTION2")}
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {t("USR.START.CHAT")}
                                </Tooltip>
                              }
                            >
                              <Button
                                className="mt-2 all_btn"
                                size="sm"
                                disabled={i.profstatus !== 1}
                                onClick={() => {
                                  checkLoginAndGetConsultant(
                                    i.consultantUuid,
                                    i.consultantName,
                                    "Chat",
                                    i.ratePerMin
                                  );
                                }}
                              >
                                {t("USR.CHAT.BTN")}
                              </Button>
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      </Card.Body>
                      {i.profstatus === 1 ? (
                        <Card.Footer
                          className="text-center all_border"
                          style={{ color: "green", height: 45 }}
                        >
                          <strong>{t("USR.HOM.PR.ONL")}</strong>
                          &nbsp;
                          <Spinner
                            animation="grow"
                            variant="success"
                            size="sm"
                          />
                          {/* <GoProject color="RED" size={20} /> */}
                        </Card.Footer>
                      ) : null}
                      {i.profstatus === 2 ? (
                        <Card.Footer
                          className="text-center all_border"
                          style={{ color: "red", height: 45 }}
                        >
                          <strong>{t("USR.HOM.PR.OFF")}</strong>
                          &nbsp;
                          <GoProject color="RED" size={20} />
                        </Card.Footer>
                      ) : null}
                      {i.profstatus === 3 ? (
                        <Card.Footer
                          className="text-center all_border"
                          style={{ color: "green", height: 45 }}
                        >
                          <strong>{t("USR.HOM.PR.ONCA")}</strong>
                          &nbsp;
                          <Spinner
                            animation="grow"
                            variant="success"
                            size="sm"
                          />
                        </Card.Footer>
                      ) : null}
                    </Card>
                  </Col>
                ) : null}
              </>
            ))}
          </>
        ) : (
          <center>
            <h5>No Consultant Found</h5>
          </center>
        )}
      </Row>
    </div>
  );
};

const Search = ({ details }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const [searchField, setSearchField] = useState("");
  const [searchSkill, setSearchSkill] = useState("ALL");
  const [allLang, setAllLang] = useState([]);
  const [allSkill, setAllSkill] = useState([]);
  const [savemoney, setSaveMoney] = useState({ balance: 0 });
  const userBalance = savemoney || savemoney.balance;
  const [initialProf, setInitialProf] = useState(details);
  const [searchLang, setSearchLang] = useState("All");
  const [loading, setLoading] = useState(false);
  console.log("details", details);
  const [selectedConsultantService, setSelectedConsultantService] = useState();
  console.log("savemoney", savemoney);

  useEffect(() => {
    vasthuUrl
      .get(`vasthu/openapi/getskills`)
      .then((res) => {
        setAllSkill(res.data);
        console.log("all skills", res.data);
      })
      .catch((err) => {
        setAllSkill([]);
        alert("can't get Skills");
      });
    vasthuUrl
      .get("openapi/lang/allCode")
      .then((res) => {
        console.log("available lang", res.data);
        setAllLang(res.data);
      })
      .catch((err) => {
        setAllLang([]);
        alert("can't get lang");
      });
  }, []);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/getuserbalance/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSaveMoney(res.data);
          console.log("getuserbalance", res.data);
          sessionStorage.setItem("storedBalance", res.data.balance);
          console.log("vvvvvvvvvvvv", res.data.balance);
        });
    } catch (e) {
      console.log("service", e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`info/professional/byskillorlang`, {
          skill: searchSkill,
          langCode: searchLang,
        })
        .then((res) => {
          setInitialProf(res.data);
          setLoading(false);
          console.log("con filter out", res.data);
          if (searchSkill == "Vasthu Consultation") {
            setSelectedConsultantService(3);
          } else if (searchSkill == "Astrology Consultation") {
            setSelectedConsultantService(4);
          } else if (searchSkill == "Building Consultation") {
            setSelectedConsultantService(5);
          } else {
            setSelectedConsultantService();
          }
        });
    } catch (error) {
      setInitialProf((prev) => prev);
      setLoading(false);
      console.log("consultlist", error);
    }
  }, [searchSkill, searchLang]);

  const filteredPersons = initialProf.filter((person) => {
    return person.consultantName
      .toLowerCase()
      .includes(searchField.toLowerCase());
  });
  console.log("filteredPersonss", filteredPersons);

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const handleSkill = (e) => {
    setSearchSkill(e.target.value);
  };
  const handleLang = (e) => {
    setSearchLang(e.target.value);
  };

  return (
    <div className="border rounded all_background p-1">
      <center>
        <h1>{t("CM.CNSL.ON.TL")}</h1>
      </center>

      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <h4>
          {token !== null ? (
            <b>
              {t("USR.AVABLE.BALCE")}: ₹ {savemoney.balance}
            </b>
          ) : null}
        </h4>
        <Button
          className="mt-2 all_btn"
          onClick={() => {
            if (token !== null) {
              navigate("/walletoffer");
            } else {
              window.sessionStorage.setItem("rerender", "walletoffer");
              navigate("/login");
            }
          }}
          style={{ float: "right" }}
        >
          {t("USR.RECHARGE")}
        </Button>
      </div>
      <div style={{ color: "red" }} className="mt-2">
        <h4>
          <center> {t("USR.WALT..BALCE.ALT.MSG")}</center>
        </h4>
      </div>

      <div class="container">
        <div class="row d-flex justify-content-end align-items-center my-1">
          <div class="col-md-8 mt-2">
            <div class="form">
              <input
                type="text"
                class="form-control form-input form_feild"
                placeholder={t("CM.CNSL.SER.PL")}
                onChange={handleChange}
              />
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <Form.Select
              value={searchSkill}
              className="form_feild"
              onChange={handleSkill}
            >
              <option value="ALL">{t("CM.CNSL.ON.SER1")}</option>

              {allSkill.map((i) => (
                <option value={i.skillName} key={i.skillName}>
                  {i.skillName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div class="col-md-2 mt-2">
            <Form.Select
              value={searchLang}
              className="form_feild"
              onChange={handleLang}
            >
              <option value="ALL">{t("CM.CNSL.ON.SER2")}</option>
              {allLang.map((i) => (
                <option value={i.langCode} key={i.id}>
                  {i.language}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>

      {!loading ? (
        <SearchList
          filteredPersons={filteredPersons}
          service={selectedConsultantService}
          wallet={savemoney}
        />
      ) : (
        <center>
          <Spinner animation="border" />
        </center>
      )}
    </div>
  );
};

export default Search;
