import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCartPlus, FaRocketchat } from "react-icons/fa";
import {
  FcAddDatabase,
  FcBusinessContact,
  FcBusinessman,
  FcCellPhone,
  FcDoughnutChart,
  FcRating,
  FcServices,
  FcUndo,
  FcViewDetails,
} from "react-icons/fc";
import { GiWallet } from "react-icons/gi";
import { MdMoreTime } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import "../App.css";
import { vasthuUrl } from "../component/Api";
import { AdminProvider } from "../context/AdminContext";
import { UseAuth } from "../context/Auth";
import AdminChatHistory from "./Admin/AdminChatHistory";
import BannerList from "./Admin/BannerList";
import MinutesMaster from "./Admin/MinutesMaster";
import ProductList from "./Admin/ProductList";
import WalletOfferList from "./Admin/WalletOfferList";
import AdminAddRole from "./AdminAddRole";
import AdminDash from "./AdminDash";
import AdminProfile from "./AdminProfile";
import AdminRefundList from "./AdminRefundList";
import AdminUserList from "./AdminUserList";
import AudioCallAdmin from "./AudioCallAdmin";
import ConsultantTabs from "./ConsultantTabs";
import PaymentDateHistory from "./PaymentDateHistory";
import Review from "./Review";
import Service from "./Service";
import AdminAudioCallHistory from "./Admin/AdminAudioCallHistory";
import AdminConsultantChatHistory from "./Admin/AdminConsultantChatHistory";
import Masters from "./Admin/Masters";

// import Translation from "./Admin/Translation";

const AdminTabs = () => {
  const { onLogout } = UseAuth();
  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const AuthName = window.sessionStorage.getItem("authname");

  useEffect(() => {
    try {
      const res = vasthuUrl
        .get(`getuserprofiledetail/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setImgUrl(res.data.profileUrl);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  }, []);

  return (
    <AdminProvider>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="admindash"
        justify
        unmountOnExit={true}
        fill={true}
        mountOnEnter={true}
      >
        <Row className="m-0">
          <Col className="adminLeft text-center" sm={0} md={3}>
            <Card className=" m-1 all_border" style={{ width: "100%" }}>
              <Card.Body className="all_border">
                {imgUrl ? (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100 }}
                    src={imgUrl}
                    alt="profile"
                  />
                ) : (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100 }}
                    src={require("../images/noimage.jpg")}
                    // style={{ borderRadius: '50%', height: '50px', width: '50px', margin: '-9px', objectFit: 'cover' }}
                  />
                )}
                <Card.Title>ADMIN</Card.Title>
                <hr className="all_border" />
              </Card.Body>
              <Nav
                style={{ cursor: "pointer" }}
                variant="pills"
                className="flex-column all_border"
              >
                <Nav.Item>
                  <Nav.Link eventKey="admindash">DASHBOARD</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminaddrole">ADD USER</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userdetails">USER INFO</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminprofile">ACCOUNT</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="paymentdatehistory">
                    PAYMENT HISTORY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reviewdetails">REVIEWS</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="servicedetails">SERVICE</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="consultantinfo">CONSULTANTS</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="refunddetails">REFUNDS</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminaudiocall">AUDIO CALL</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminchathistory">CHAT HISTORY</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminaudiocallhistory">
                    CONSULTANT CALL HISTORY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="adminconsultantchathistory">
                    CONSULTANT CHAT HISTORY
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="walletofferlist">WALLET</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="adminproductlist">PRODUCT</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="masters">MASTERS</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="minutesmaster">MINUTES MASTER</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bannerlist">BANNER</Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                  <Nav.Link eventKey="admintranslationlist">TRANSLATION</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Card>
          </Col>
          <Col sm={12} md={9}>
            <Navbar className="all_background" expand={"md"}>
              <Container fluid>
                {/* <Navbar.Brand href="#">VasthuParam</Navbar.Brand> */}
                <Navbar.Brand href="#" className="admin_user">
                  <div>{t("CM_PUB_LOGO_TITLE")}</div>
                  {AuthName ? (
                    <h3 style={{ marginRight: "1rem" }}>{AuthName}</h3>
                  ) : null}
                </Navbar.Brand>
                <Nav>
                  <div className="px-2">
                    <Button
                      variant="light"
                      size="md"
                      style={{
                        fontWeight: "bold",
                        width: "-webkit-fill-available",
                        marginBottom: "2px",
                      }}
                      onClick={onLogout}
                    >
                      {/* {t("PR.LOUT")} */}
                      LOGOUT
                    </Button>
                  </div>

                  {/* <div className="px-1">
                    <LangChanger />
                  </div> */}
                </Nav>
              </Container>
            </Navbar>

            <Col className="adminTabMobile">
              <Card>
                <Nav
                  variant="pills"
                  className="justify-content-center text-center py-1"
                >
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="admindash"
                      href="#"
                    >
                      <FcDoughnutChart size={30} />
                      <br />
                      DASHBOARD
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminaddrole"
                      href="#"
                    >
                      <FcAddDatabase size={30} />
                      <br />
                      ADD USER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="userdetails"
                      href="#"
                    >
                      <FcViewDetails size={30} />
                      <br />
                      USER INFO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminprofile"
                      href="#"
                    >
                      <FcBusinessContact size={30} />
                      <br />
                      ACCOUNT
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="paymentdatehistory"
                      href="#"
                    >
                      <RiSecurePaymentLine size={30} />
                      <br />
                      PAYMENT HISTORY
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="reviewdetails"
                      href="#"
                    >
                      <FcRating size={30} />
                      <br />
                      REVIEWS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="servicedetails"
                      href="#"
                    >
                      <FcServices size={30} />
                      <br />
                      SERVICE
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="consultantinfo"
                      href="#"
                    >
                      <FcBusinessman size={30} />
                      <br />
                      CONSULTANTS
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="refunddetails"
                      href="#"
                    >
                      <FcUndo size={30} />
                      <br />
                      REFUNDS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminaudiocall"
                      href="#"
                    >
                      <FcCellPhone size={30} />
                      <br />
                      AUDIO CALL
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminchathistory"
                      href="#"
                    >
                      <FaRocketchat size={30} />
                      <br />
                      CHAT HISTORY
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminaudiocallhistory"
                      href="#"
                    >
                      <FcCellPhone size={30} />
                      <br />
                      CONSULTANT CALL HISTORY
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminconsultantchathistory"
                      href="#"
                    >
                      <FaRocketchat size={30} />
                      <br />
                      CONSULTANT CHAT HISTORY
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="walletofferlist"
                      href="#"
                    >
                      <GiWallet size={30} />
                      <br />
                      WALLET
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="adminproductlist"
                      href="#"
                    >
                      <FaCartPlus size={30} />
                      <br />
                      PRODUCT
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="masters"
                      href="#"
                    >
                      <MdMoreTime size={30} />
                      <br />
                       MASTERS
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="minutesmaster"
                      href="#"
                    >
                      <MdMoreTime size={30} />
                      <br />
                      MINUTES MASTER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="bannerlist"
                      href="#"
                    >
                      <FaCartPlus size={30} />
                      <br />
                      BANNER
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item>
                    <Nav.Link
                      style={{ fontSize: 10 }}
                      eventKey="admintranslationlist"
                      href="#"
                    >
                      <FaCartPlus size={30} />
                      <br />
                      TRANSLATION
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Card>
            </Col>

            <Tab.Content className="consultant_tab_content">
              <Tab.Pane className="mt-1 " eventKey="admindash">
                <center>
                  <h4 className="m-1">
                    <u>TOTAL USERS</u>
                  </h4>
                </center>
                <AdminDash />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminaddrole">
                <AdminAddRole />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="userdetails">
                <AdminUserList />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminprofile">
                <AdminProfile />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="paymentdatehistory">
                <PaymentDateHistory />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="reviewdetails">
                <Review />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="servicedetails">
                <Service />
              </Tab.Pane>
              {/* <Tab.Pane className="mt-1" eventKey="consultantinfo">
                <ConsultantTabs />
              </Tab.Pane> */}
              <Tab.Pane className="mt-1" eventKey="refunddetails">
                <AdminRefundList />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminaudiocall">
                <AudioCallAdmin />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminchathistory">
                <AdminChatHistory />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminaudiocallhistory">
                <AdminAudioCallHistory />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="adminconsultantchathistory">
                <AdminConsultantChatHistory />
              </Tab.Pane>
              {/* <Tab.Pane className="mt-1" eventKey="walletofferlist">
                <WalletOfferList />
              </Tab.Pane> */}
              <Tab.Pane className="mt-1" eventKey="adminproductlist">
                <ProductList />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="masters">
                <Masters />
              </Tab.Pane>
              {/* <Tab.Pane className="mt-1" eventKey="minutesmaster">
                <MinutesMaster />
              </Tab.Pane>
              <Tab.Pane className="mt-1" eventKey="bannerlist">
                <BannerList />
              </Tab.Pane> */}
              {/* <Tab.Pane className="mt-1" eventKey="admintranslationlist">
                <Translation />
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </AdminProvider>
  );
};

export default AdminTabs;
