import React, { useState, useEffect, Suspense } from "react";
import { vasthuUrl } from "../component/Api";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Accordion, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";
import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import Toast from "react-bootstrap/Modal";
import { format, isToday } from "date-fns";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";

const AudioCallProfessional = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [callStatus, setCallStatus] = useState("Audiocall List");
  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const [consulName, setConsulName] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([
    {
      callFrom: "",
      callTo: "",
      duration: "",
      offerId: "",
      offerName: "",
      status: "",
      proposedCallDuration: "",
    },
  ]);

  const [toast, setToast] = useState(false);

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const closeTosastBtn = () => {
    setToast(false);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`vasthu/user/getAudioCallProHistory/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((rs) => {
          console.log("achistory", rs.data);
          setAudiocallHistoryList(rs.data);
          console.log("rs.data", rs.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("setAllOffer", e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      vasthuUrl
        .get(`vasthu/user/getAudioCallProHistoryByDate/${uuid}`, {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            // consultantName: selectedConsultant,
          },
        })
        .then((res) => {
          const data = res.data.calls;
          if (data.length == 0) {
            setAudiocallHistoryList("");
          } else {
            setAudiocallHistoryList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching filtered data:", error);
        });
    }
  }, [startDate, endDate]);

  const GetConsultantCallStatus = (id) => {
    setConsulName(id.callFrom);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id.call_to_id,
          user_uuid: id.call_from_id,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          console.log("res.data", res.data);
          if (res.data.proCurrentCallStatus == "in-progress") {
            setToast(true);
            setWaitingTime(Math.round(res.data.waitingTime));
            console.log(res.data);
          } else {
            openModelCall(id);
          }
        });
    } catch (err) {
      console.log("SERVER ERROR", err);
    }
  };

  const openModelCall = (id) => {
    const res = vasthuUrl
      .post(
        `vasthu/user/clicktocall`,
        {
          from: id.call_from_id, //"09790580304",
          to: id.call_to_id, //"07092758264",
          duration: id.proposedCallDuration, //30min
          subscriptionId: id.subscriptionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("postresponse", res);
        if (res.status == "200") {
          setCallStatus(t("USR.CAL.PROCED.BTN"));
          navigate("/audiocallstatus", {
            state: { callStatus: "USR.CAL.PROCED.BTN" },
          });

          // setTimeout(() => {
          //   navigate("/audiocall", { replace: true });
          // }, 30000);
        } else {
          setCallStatus(t("USR.CAL.FAILD.BTN"));
          console.log(t("USR.CAL.FAILD.BTN"));
        }
      })
      .catch((err) => {
        setCallStatus(t("USR.CAL.FAILD.BTN"));

        navigate("/audiocallstatus", {
          state: { callStatus: "USR.CAL.FAILD.BTN" },
        });
      });
  };

  // const listtemp = Array.isArray(audiocallHistoryList)
  //   ? audiocallHistoryList.map((obj, index) => ({
  //       ...obj,
  //       id: index + 1,
  //     }))
  //   : [];

  const columns = [
    { field: "id", headerName: t("CMN.S.NO"), width: 80 },
    // { field: "callFrom", headerName: t("USR.HOM.HD.UN"), width: 150 },
    { field: "callTo", headerName: t("CON.AUD.CAL.HIS.CLT"), width: 150 },
    {
      field: "start_time",
      headerName: t("TXT.CAL.DAT.LO"),
      renderCell: (params) =>
        dayjs(params.row.start_time).format("DD-MM-YYYY HH:MM"),
      width: 150,
    },
    {
      field: "proposedCallDuration",
      headerName: t("TXT.TOT.CAL.DUR.LO"),
      width: 150,
    },
    {
      field: "duration",
      headerName: t("TXT.DUR.SPOK.LO"),
      width: 150,
    },
    {
      field: "amount_to_paid",
      headerName: "Earnings",
      width: 150,
    },
    // { field: "amount_charged", headerName: t("CON.AMT.CHR"), width: 150 },
    // { field: "offerName", headerName: t("USR.PY.HS.TB.COL1"), width: 150 },
    {
      field: "actions",
      headerName: t("CON.AUD.CAL.HIS.CONT"),
      type: "actions",
      width: 200,
      renderCell: (i) => (
        <>
          {i.row.status === "completed" ? (
            "-"
          ) : (
            <Button
              className="all_btn"
              disabled={
                !isToday(new Date(i.row.start_time)) ||
                !(
                  parseInt(i.row.proposedCallDuration) >
                  parseInt(i.row.duration)
                ) ||
                i.row.status === "cancelled" ||
                i.row.status === "in-progress" ||
                i.row.status === "onhold"
              }
              onClick={async () => {
                GetConsultantCallStatus(i.row);
              }}
            >
              {t("CON.AUD.CAL.HIS.JOIN.BTN")}
            </Button>
          )}
        </>
      ),
    },
    { field: "status", headerName: t("TXT.CAL.STS.LO"), width: 150 },
  ];

  return (
    <React.Fragment>
      <Row className="mt-2 consul_chat_his" style={{ alignItems: "flex-end" }}>
        <Col sm={12} md={4} className="mt-2">
          {/* <input
            type="text"
            className="form-control form-input form_feild"
            placeholder={t("CM.CNSL.SER.PL")}
            onChange={(e) => setSearchField(e.target.value)}
          /> */}
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            Start Date:
            <DatePicker
              className="form_feild"
              value={startDate}
              format="dd-MM-yy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Select start date"
              style={{ height: "35px", borderRadius: "7px" }}
              maxDate={endDate ? endDate : today}
            />
          </label>
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            End Date:
            <DatePicker
              className="form_feild"
              value={endDate}
              format="dd-MM-yy"
              onChange={(date) => setEndDate(date)}
              placeholderText="Select end date"
              minDate={startDate}
              maxDate={today}
            />
          </label>
        </Col>
      </Row>

      {!loading ? (
        <>
          <div>
            <center>
              <h4 className="m-1">
                <u>{t("USR.AUDCH.TEXT1")}</u>
              </h4>
            </center>
          </div>

          {audiocallHistoryList.calls &&
          audiocallHistoryList.calls.length > 0 ? (
            <Accordion className="chat_accordian mt-2" defaultActiveKey="0">
              <Accordion.Item
                className="accordian_style chat_accordian"
                eventKey="0"
              >
                <Accordion.Header>
                  <Row style={{ width: "inherit" }}>
                    <Col sm={12} md={6}>
                      <h5>Duration: {audiocallHistoryList.totalDuration}</h5>
                    </Col>
                    <Col sm={12} md={6}>
                      <h5>Salary: ₹{audiocallHistoryList.salaryAmount}</h5>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="chat_acc_body">
                  <div>
                    <center className="datagrid mt-3">
                      <Grid container className="deskTable datagrid">
                        <DataGrid
                          className="all_border text-center me-3 data_grid"
                          columns={columns}
                          rows={audiocallHistoryList.calls || []}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowId={(row) => row.id}
                        />
                      </Grid>
                    </center>
                    <div className="mobileCard justify-content-center">
                      {audiocallHistoryList.calls &&
                      audiocallHistoryList.calls.length > 0 ? (
                        audiocallHistoryList.calls.map((item, index) => (
                          <NewBootCard key={index}>
                            {/* <h6>User: {item.callFrom}</h6> */}
                            <h6>Client: {item.callTo}</h6>
                            <h6>Duration: {item.duration} seconds</h6>
                            <h6>Earnings: {item.amount_to_paid}</h6>
                            <h6>
                              OfferName:{" "}
                              {item.offerName ? item.offerName : "Wallet Call"}
                            </h6>
                            <h6>
                              Start_time:{" "}
                              {formatDate(new Date(item.start_time))}
                            </h6>
                            <h6>status: {item.status}</h6>
                            <h6>CallDuration: {item.proposedCallDuration}</h6>
                          </NewBootCard>
                        ))
                      ) : (
                        <h6>No Call Details Available</h6>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <center>
              <h5>No Records Found</h5>
            </center>
          )}
        </>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}

      <Toast onHide={() => setToast(false)} show={toast} className="all_border">
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          Hello! <b>{consulName}</b> {t("TXT.CON.BSY.ANT.PLS.LO")} {waitingTime}{" "}
          minutes{" "}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            close
          </Button>
        </Toast.Footer>
      </Toast>
    </React.Fragment>
  );
};

export default AudioCallProfessional;
