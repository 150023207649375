import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { RiMessage2Line } from "react-icons/ri";
import {
  Navbar,
  Container,
  Offcanvas,
  Nav,
  NavDropdown,
  Image,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { GiLotus } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import LangChager from "./LangChanger";
import { FiPhoneCall } from "react-icons/fi";
import { RiCustomerService2Fill } from "react-icons/ri";
// import { Divider } from "antd";
import Divider from "@mui/material/Divider";

const Header = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang1 = window.localStorage.getItem("i18nextLng");
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  return (
    <React.Fragment>
      <div>
        <Navbar
          // bg="#392600"
          expand={props.breakpionts}
          style={{
            zIndex: 2,
            // borderBottom: "1px solid #ffbb33",
            // backgroundColor: "#FFBB34",
            backgroundColor: "#ffbb34",
          }}
          className="nav_header"
        >
          <Container>
            {/* <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${props.breakpionts}`}
            /> */}
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-lg`}
              onClick={() => setShowOffcanvas(!showOffcanvas)}
            />
            <Navbar.Brand style={{ cursor: "pointer" }}>
              <NavLink to="/" className="nav_linkhead">
                <Image
                  src="Vlogo4.png"
                  class="img-fluid"
                  object="fill"
                  width={40}
                  height={40}
                  style={{
                    backgroundColor: "#392600",
                    borderRadius: "7px",
                    marginRight: "10px",
                  }}
                  //#392600
                />
                {/* <GiLotus
                  color="#ffbb33"
                  size={30}
                  width={30}
                  height={30}
                  className="d-inline-block align-top"
                /> */}
                {t("CM_PUB_LOGO_TITLE")}
              </NavLink>
            </Navbar.Brand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${props.breakpionts}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${props.breakpionts}`}
              placement="start"
              className="home-drawer"
              style={{ width: "70%" }}
              show={showOffcanvas}
              onHide={handleOffcanvasClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${props.breakpionts}`}
                >
                  <Image
                    height={60}
                    width={65}
                    alt="logo"
                    src="Vlogo4.png"
                    style={{
                      backgroundColor: "#392600",
                      borderRadius: "7px",
                      marginRight: "10px",
                    }}
                    // src="https://st2.depositphotos.com/1071184/5317/v/950/depositphotos_53176401-stock-illustration-lotus-flower-abstract-vector-logo.jpg"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="home-drawer">
                <Nav
                  className={
                    lang1 === "en"
                      ? "eng_font justify-content-end flex-grow-1 pe-3 header_navbar"
                      : lang1 === "ta"
                      ? "tam_font justify-content-end flex-grow-1 pe-3 header_navbar"
                      : "hi_font justify-content-end flex-grow-1 pe-3 header_navbar"
                  }
                >
                  <NavDropdown
                    title={t("CM.HD.CLT.MEN")}
                    className="nav_dpd_title"
                  >
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/vasthuastrolgers"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.CLT.MEN.ASTR")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider className="border-warning" />
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/vasthuexpert"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.CLT.MEN.VE")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider className="border-warning" />
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link building_navlink"
                        to="/vasthuarchitects"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.CLT.MEN.AR")}
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={t("CM.HD.KM.MEN")}
                    className="nav_dpd_title"
                  >
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/vasthuarticle"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.KM.MEN.ART")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider className="border-warning" />
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/vasthubooks"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.KM.MEN.BK")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider className="border-warning" />
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/astrocontent"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.CLT.MEN.AST")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Divider className="border-warning" />
                    <NavDropdown.Item>
                      <NavLink
                        className="nav_link"
                        to="/vasthucontent"
                        onClick={handleOffcanvasClose}
                      >
                        {t("CM.HD.CLT.MEN.VST")}
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown.Divider className="all_border" />
                  {/* changing for before login */}
                  <NavLink
                    to="/login"
                    className="nav_linkhead"
                    
                  >
                    {t("CM.HD.SI")}
                  </NavLink>
                  {/* <a
                    href="/login"
                    className="nav_linkhead"
                    onClick={handleOffcanvasClose}
                  >
                    {t("CM.HD.SI")}
                  </a> */}
                  <NavLink
                    to="/aboutus"
                    className="nav_linkhead"
                    onClick={handleOffcanvasClose}
                  >
                    {t("CM.HD.ABT")}
                  </NavLink>
                  {/* <a
                    href="/aboutus"
                    className="nav_linkhead"
                    onClick={handleOffcanvasClose}
                  >
                    {t("CM.HD.ABT")}
                  </a> */}
                  <NavDropdown.Divider />
                  {/* <NavLink
                    to="/register"
                    className="nav_linkhead"
                    onClick={handleOffcanvasClose}
                  >
                    {t("CM.HD.SU")}
                  </NavLink> */}
                  <NavDropdown.Divider />
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <LangChager />
          </Container>
        </Navbar>
        {/* <div style={{width:'100%',border:'1px solid #fff'}}>
          
        </div> */}
        <Divider />

        <Outlet style={{ zIndex: 1 }} />
      </div>
    </React.Fragment>
  );
};

Header.defaultProps = {
  title: "VasthuParam",
};
Header.propsTypes = {
  title: PropTypes.string.isRequired,
};
export default Header;
