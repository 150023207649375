import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import Title from "../component/Title";
import { vasthuUrl } from "./Api";
import BillDeskPay from "./BillDeskPay";

const Offer = () => {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [freeOffers, setFreeOffers] = useState([]);
  const [detail, setDetail] = useState([]);
  const [offerplan, setOfferplan] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [timeStampFormat, setTimeStampFormat] = useState("");

// Offer Detail Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDetail(id);
    setOfferplan(id.offerDetails);
  };

// Payment Response Modal
  const [model, setModel] = useState(false);
  const modelClose = () => {
    setModel(false);
  };
  const modelOpen = (id) => {
    setModel(true);
  };
 

  useEffect(() => {
    try {
      vasthuUrl
        .get("/offer/getAllSpecialOffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFreeOffers(res.data);
          console.log("res.data", res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setFreeOffers([]);
    }
  }, []);

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const dateStr =
    paymentDetails && paymentDetails.timestamp ? paymentDetails.timestamp : "";
  useEffect(() => {
    if (dateStr) {
      const [datePart, timePart] = dateStr.split(" "); // Split date and time parts
      const [day, month, year] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);
      const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);
      if (!isNaN(dateObj.getTime())) {
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Use 12-hour clock with AM/PM
        };
        const formattedDate = dateObj.toLocaleString("en-US", options);
        console.log(formattedDate); // Output: "04/18/2024, 12:03:59 PM"
        setTimeStampFormat(formattedDate);
      } else {
        console.error("Invalid date format:", dateStr);
      }
    } else {
      console.error("Date string is undefined or null.");
    }
  }, [dateStr]);


  const handleUse = (p, q) => {
    switch (p.serviceId) {
      case 1:
        return navigate("/plotvasthu");
        break;
      case 2:
        return navigate("/homevasthu");
        break;
      case 3:
        return navigate("/consultantbooking", {
          state: {
            p: {
              serviceId: p.serviceId,
              subscriptionId: q.id,
              duration: p.duration,
            },
            q: p.id,
          },
        });
        break;
      case 4:
        return navigate("/consultantbooking", {
          state: {
            p: {
              serviceId: p.serviceId,
              subscriptionId: q.id,
              duration: p.duration,
            },
            q: p.id,
          },
        });
        break;
      case 5:
        return navigate("/consultantbooking", {
          state: {
            p: {
              serviceId: p.serviceId,
              subscriptionId: q.id,
              duration: p.duration,
            },
            q: p.id,
          },
        });
        break;
    }
  };

  const payHandler = (txn) => {
    console.log("pay response out", txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => console.log("after txnsave", res.data))
            .catch((err) => console.log("after txnsave try", err));

          console.log("buying .. subsc");

          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("afterbuysubscr", res.data);
                modelOpen();
                setPaymentDetails(res.data);
              });
          }, 2000);
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: parseInt(res.data.additional_info.additional_info2),
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
              navigate("/customeraccount");
            });
        }
      });
  };

  const handleBuy = async (id, price) => {
    if (token) {
      try {
        await vasthuUrl
          .post(
            `vasthu/openapi/createorderapi`,
            {
              userUuid: userId,
              offerId: id,
              price: price,
              currency: "356",
              lng: lang,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("billDesk", res.data);
            handleClose();
            BillDeskPay(res.data, payHandler)
              .then((config) => {
                console.log("payout", config);
                window.loadBillDeskSdk(config);
              })
              .catch((err) => console.log("payment initialize error", err));
          });
      } catch (err) {
        console.log("offer buy in home screen", err);
      }
    } else {
      navigate("/login");
    }
  };

  const handleFreeOffer = (i) => {
    vasthuUrl
      .post(
        "subscription/buyVasthuOffer",
        {
          orderId: "FREE",
          userUuid: userId,
          offerId: i.id,
          paymentStatus: true, // test
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("Payment status changed to trueFREE");
        console.log("afterbuysubscrFREE", res.data);
        // setPaymentDetails(res.data);
        // modelOpen();
        // navigate("/customeraccount");
        //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
        handleUse(i, res.data);
      });
  };

  return (
    <Container className="my-2 text-center ">
      <div className="p-4 all_background" style={{ borderRadius: 10 }}>
        <Title title={t("USR.HOM.SPCL.OFF")} />
        {freeOffers.length > 0 ? (
          <>
            <Slider
              arrows={true}
              slidesToShow={1}
              slidesToScroll={1}
              lazyLoad={true}
              dots={true}
              autoplay={true}
              responsive={[
                {
                  breakpoint: 2560,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                  },
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {freeOffers.map((i, j) => (
                <div className="p-2">
                  <Card
                    className="all_border special_offer_card"
                    key={i.offerId}
                  >
                    <Row className="special_offer_row">
                      <Col>
                        <Image
                          src={i.imgURL}
                          fluid
                          className="special_offer_img"
                        />
                      </Col>
                      <Col>
                        <div className="scroll_bar special_content">
                          <div
                            style={{
                              fontSize: "medium",
                              fontWeight: "bold",
                              position: "sticky",
                              top: "0",
                              width: "100%",
                              backgroundColor: "#f4dec2",
                            }}
                          >
                            {i.offerName}
                          </div>
                          <div style={{ fontSize: "medium" }}>
                            {t("USR.ACC.PEN.PRI")} : ₹
                            {i.offerPrice === 0 ? "Free" : i.offerPrice}&nbsp;
                            <s>{i.totalBasePrice}</s>
                          </div>
                          <div style={{ fontSize: "medium" }}>
                            {t("PR.APMNT.NATAB.LB.CD")}:{i.duration}
                            {t("TXT.MIN.LO")}
                          </div>
                          {i.offerDetails.map((i) => (
                            <>
                              <div className="special_service">
                                <li>{i.serviceName}</li>
                              </div>
                            </>
                          ))}
                          <div className="special_des">{i.description}</div>
                        </div>
                        <Button
                          className="special_btn mt-2"
                          onClick={() => handleShow(i)}
                        >
                          {i.offerPrice === 0
                            ? t("BTN.USE.NOW.LO")
                            : t("USR.ACC.PEN.BUY.BTN")}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <center>
            <h3>{t("USR.HOM.SPCL.OFF.ND")}</h3>
          </center>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header all_background" closeButton>
          <Modal.Title>{t("USR.HOM.SPL.DTL")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{t("TXT.OFF.NM.LO")}:</b> {detail.offerName}
          </p>
          <p>
            <b>{t("USR.HOM.SPL.OP")}:</b> ₹
            {detail.offerPrice === 0 ? "Free" : detail.offerPrice}
          </p>
          <p>
            <b>{t("USR.HOM.SPL.TP")} :</b> <s>₹{detail.totalBasePrice}</s>
          </p>
          <p>
            <b>{t("USR.HOM.SPL.OSD")}:</b>
            {detail.offerStartDate ? (
              <span>
                {formatDate(
                  new Date(detail.offerStartDate && detail.offerStartDate)
                )}
              </span>
            ) : (
              <></>
            )}
          </p>
          <p>
            <b>{t("USR.HOM.SPL.OED")} :</b>
            {detail.offerEndDate ? (
              <span>
                {formatDate(
                  new Date(detail.offerEndDate && detail.offerEndDate)
                )}
              </span>
            ) : (
              <></>
            )}
          </p>
          <p>
            <b>{t("TXT.DES.LO")} :</b> {detail.description}
          </p>
          <Table
            striped
            bordered
            hover
            responsive
            className="all_border text-center"
          >
            <thead>
              <tr>
                <th>{t("USR.HOM.SPL.SN")}</th>
                <th>{t("USR.HOM.SPL.BP")}</th>
                <th>{t("USR.HOM.SPL.DP")}</th>
                <th>{t("USR.HOM.SPL.D")}</th>
                <th>{t("USR.HOM.SPL.AP")}</th>
              </tr>
            </thead>
            <tbody>
              {detail.offerDetails
                ? detail.offerDetails.map((x, y) => (
                    <tr key={y}>
                      <td>
                        {" "}
                        {x.serviceName}
                        {/* (30{t("TXT.MIN.LO")}) */}
                        (15{t("TXT.MIN.LO")})
                      </td>
                      <td> {x.basePrice}</td>
                      <td> {x.discountedPrice}</td>
                      <td> {x.discountPercentage}</td>
                      <td> {x.actualPrice}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer
          className="all_border"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button className="all_btn" onClick={handleClose}>
            {t("USR.HOM.PLN.OF.CN")}
          </Button>
          {detail.offerPrice === 0 ? (
            <Button
              size="md"
              className="all_btn"
              onClick={() => handleFreeOffer(detail)}
            >
              {t("BTN.USE.NOW.LO")}
            </Button>
          ) : (
            <Button
              size="md"
              className="all_btn"
              onClick={() => handleBuy(detail.id, detail.offerPrice)}
            >
              {t("USR.ACC.PEN.BUY.BTN")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={model} onHide={modelClose} className="all_border">
        <Modal.Header closeButton className="all_background">
          <Modal.Title>{t("USR.HOM.PLN.PDT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="all_border">
          <>
            {paymentDetails ? (
              <>
                <p>
                  {t("TXT.OFF.NM.LO")} : {detail.offerName}
                </p>
                <p>
                  {t("USR.HOM.PLN.DTL.OP")} : {detail.offerPrice}
                </p>
                {offerplan.map((service) => (
                  <div>
                    <p>
                      {t("USR.HOM.SPL.SN")} <li>{service.serviceName}</li>
                    </p>
                  </div>
                ))}
                <p>
                  <b>{t("USR.HOM.PLN.PS")} :</b> {paymentDetails.message}
                </p>
                <p>
                  <b>{t("USR.HOM.PLN.OF.PD")} :</b> {timeStampFormat}
                </p>
              </>
            ) : null}

            <Button
              class="btn btn-default"
              className="all_btn"
              size="md"
              style={{
                float: "right",
              }}
              // onClick={() => modelClose()}
              onClick={() => {
                handleUse(detail, paymentDetails);
                modelClose();
              }}
              active
            >
              {t("BTN.USE.NOW.LO")}
            </Button>
          </>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default Offer;
