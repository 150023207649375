import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";
import Toast from "../../component/Toast";
import validator from "validator";

function SalaryPercentage() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [reLoad, setReLoad] = useState(false);
  const [minList, setMinList] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [newMinute, setNewMinute] = useState([]);
  const [allSalary, setAllSalary] = useState([]);
  // console.log("saveData", saveData);
  console.log("allSalary", allSalary);

  useEffect(() => {
    vasthuUrl
      .get("admin/getLatestSalary", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        setReLoad(false);
        setMinList(res.data);
      })
      .catch((error) => {
        console.error("Error fetching latest salary:", error);
      });

    vasthuUrl
      .get("admin/getAllSalary", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        setReLoad(false);
        setAllSalary(res.data);
        console.log('res.data', res.data)
      })
      .catch((error) => {
        console.error("Error fetching latest salary:", error);
      });
  }, [reLoad]);
  

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  // Create or Update Minute MODAL
  const [model, setModel] = useState(false);
  const ModalOpen = () => {
    setModel(true);
  };

  const ModalClose = () => {
    setModel(false);
    setNewMinute();
    setSaveData("");
  };

  const handleShow = (i) => {
    // console.log("i", i);
    ModalOpen(i);
    setSaveData(i);
  };

  // const handleCreateMinute = async () => {
  //   if (validator.isEmpty(newMinute)) {
  //     alert("Please enter a valid salary percentage.");
  //     setMessageType("error");
  //     return;
  //   }

  //   try {
  //     const res = await vasthuUrl.post(
  //       "admin/setSalaryPercent",
  //       { salaryPercent: newMinute },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );

  //     alert(`Successfully updated salary percentage to ${newMinute}%!`);
  //     setMessageType("success");
  //     ModalClose();
  //     setReLoad(true);
  //   } catch (error) {
  //     console.error("Error updating salary percentage:", error);
  //     alert("Failed to update salary percentage. Please try again.");
  //     setMessageType("error");
  //   }
  // };


  const handleCreateMinute = async () => {
    if (validator.isEmpty(newMinute)) {
      alert("Please enter a valid salary percentage.");
      setMessageType("error");
      return;
    }
  
    try {
      const res = await vasthuUrl.post(
        `admin/setSalaryPercent?salaryPercent=${encodeURIComponent(newMinute)}`,
        {}, // Empty body since the API expects a query param, not a request body
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      alert(`Successfully updated salary percentage to ${newMinute}%!`);
      setMessageType("success");
      ModalClose();
      setReLoad(true);
    } catch (error) {
      console.error("Error updating salary percentage:", error);
      alert("Failed to update salary percentage. Please try again.");
      setMessageType("error");
    }
  };
  
  const handleUpdateMinute = async () => {
    const minuteId = saveData.id;
    // if (!validator.isEmpty(saveData.id)) {
    const res = await vasthuUrl
      .put(
        `offer/updateMinutes/${minuteId}`,
        {
          // offerCallMins: parseInt(saveData.offerCallMins, 10),
          offerCallMins: saveData.offerCallMins,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("after update", res.data);
        alert(`Successfully Update Id ${minuteId} to ${newMinute} Minute...!`);
        setMessageType("success");
        ModalClose();
        setReLoad(true);
      });
    // } else {
    //   alert("Error");
    //   setMessageType("error");
    //   ModalClose();
    // }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      const res = await vasthuUrl.post(
        `offer/updateSalaryPercent/${id}`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(`Updating status of ${id} to ${status}`);
      alert(`Updating status of ${id} to ${status}`);
      setMessageType("success");
      setReLoad(true);
    } catch (error) {
      alert("Failed to Update");
      setMessageType("error");
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO", width: 100 },
    { field: "salaryPercent", headerName: "SALARY PERCENTAGE", width: 200 },
    { field: "updatedAt", headerName: "UPDATE DATE", width: 300 },
    // {
    //   field: "actions",
    //   headerName: "ACTION",
    //   renderCell: (params) => (
    //     <div
    //       style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
    //     >
    //       <Button
    //         className="all_btn"
    //         onClick={() => handleShow(params.row)}
    //         disabled={params.row.status === "INACTIVE"}
    //       >
    //         Update
    //       </Button>

    //       {params.row.status === "INACTIVE" ? (
    //         <Button
    //           className="mx-1 all_btn"
    //           onClick={() => handleUpdateStatus(params.row.id, "ACTIVE")}
    //         >
    //           Activate
    //         </Button>
    //       ) : (
    //         <Button
    //           className="mx-1 all_btn"
    //           onClick={() => handleUpdateStatus(params.row.id, "INACTIVE")}
    //         >
    //           Deactivate
    //         </Button>
    //       )}
    //     </div>
    //   ),
    //   width: 250,
    // },
  ];

  return (
    <div>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Button
            className="my-2 all_btn"
            style={{ float: "right" }}
            onClick={ModalOpen}
          >
            Add Salary
          </Button>
        </div>
        <center>
          <h2>Consultant Salary Percentage</h2>
        </center>
        {allSalary.length > 0 ? (
          <>
            <center className="datagrid mt-2">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border text-center me-3 data_grid"
                  rows={allSalary}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>
            <div className="mobileCard">
              {allSalary.map((i, j) => (
                <Card
                  className="mobileCard all_border"
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>S.NO:</b> {i.id}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Salary Percent:</b> {i.salaryPercent}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>Updated At:</b> {i.updatedAt}
                    </Card.Subtitle>
                  </Card.Body>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {/* <Button
                      className="all_btn"
                      onClick={() => handleShow(i)}
                      disabled={i.status === "INACTIVE"}
                    >
                      Update
                    </Button> */}

                    {/* {i.status === "INACTIVE" ? (
                      <Button
                        className="mx-1 all_btn"
                        onClick={() => handleUpdateStatus(i.id, "ACTIVE")}
                      >
                        Activate
                      </Button>
                    ) : (
                      <Button
                        className="mx-1 all_btn"
                        onClick={() => handleUpdateStatus(i.id, "INACTIVE")}
                      >
                        Deactivate
                      </Button>
                    )} */}
                  </div>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )}
      </div>

      <Modal
        show={model}
        onHide={ModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Card className="p-2">
          <Card.Header className="all_background">
            <center>
              <b>Consultant Salary Percentage</b>
            </center>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="walletOffername">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Enter Salary Percentage
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form_feild"
                  placeholder="Enter salary percentage"
                  value={saveData ? saveData.offerCallMins : newMinute}
                  onChange={(e) => {
                    setSaveData({ ...saveData, offerCallMins: e.target.value });
                    setNewMinute(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className="all_btn" onClick={ModalClose}>
            Close
          </Button>
          {saveData && saveData.id ? (
            <Button className="all_btn" onClick={() => handleUpdateMinute()}>
              Update
            </Button>
          ) : (
            <Button className="all_btn" onClick={() => handleCreateMinute()}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SalaryPercentage;
