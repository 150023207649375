import React, { useEffect, useState } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Title from "./Title";

// import clients from '../data/userList';
import UpdateAction from "./UpdateAction";
import theme from "./theme";
import { useContext } from "react";
import { CrmAccountContext } from "../../pages/crm/Accounts";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import { vasthuUrl } from "../Api";
import { Grid } from "@mui/material";
import HomeConsultant from "../HomeConsultant";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

import { HeaderUserContext } from "../../pages/crm/Layout";

const TableVasthu = (props) => {
  const navigate = useNavigate();

  const { userList, message, setMessage, setUserList } = useContext(
    CrmAccountContext
  );
  const [rowModesModel, setRowModesModel] = React.useState({});
  const { setCrmUser } = useContext(HeaderUserContext);

  const [consultantList, setConsultantList] = useState([]);
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("consultant_list_data", res.data, token);
        });
    } catch (e) {
      console.log("server error");
      setLoading(false);
    }
  }, [userList]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    console.log("id", id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = userList.find((row) => row.id === id);
    if (editedRow.isNew) {
      setUserList(userList.filter((row) => row.id !== id));
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200, editable: true },
    { field: "phoneNumber", headerName: "Phone", width: 150 },
    { field: "email", headerName: "Email", width: 200, editable: true },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   renderCell: (params) => <UpdateAction {...{ params }} />,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: (params) => {
        console.log("id", id);
        console.log("params", params);
        var id = params.row.id;
        var isInEditMode =
          rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <UpdateAction {...{ params }} />,
            <GridActionsCellItem
              icon={
                <CancelIcon
                  sx={{
                    color: "black",
                  }}
                />
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              sx={{
                width: 40,
                height: 40,
                bgcolor: theme.palette.primary,
                "&:hover": { bgcolor: theme.palette.primary.dark },
              }}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <EditIcon
                sx={{
                  color: "black",
                }}
              />
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            sx={{
              width: 40,
              height: 40,
              bgcolor: theme.palette.primary,
              "&:hover": { bgcolor: theme.palette.primary.dark },
            }}
          />,
          // <Button className="all_btn" onClick={() => navigate("/crmpayment")}>
          //   Wallet
          // </Button>
        ];
      },
    },
    {
      field: "Details",
      headerName: "Payments",
      width: 150,
      renderCell: (i) => (
        <Button
          className="all_btn"
          onClick={() => {
            navigate("/crmpayment", { state: { row: i.row } });
            console.log("i.row", i.row);
          }}
        >
          Recharge
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <center>
        <Title>{props.title}</Title>
      </center>
      {/* <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
        message={message}
        // action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar> */}
      {userList.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className=" datagrid">
              <DataGrid
                // rows={props.rows}
                className="all_border - text-center me-3 data_grid"
                rows={userList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          {/* <div className="justify-content-center mobileCard">
            {userList.map((i, j) => (
              <Card
                key={j}
                className="mobileCard all_border"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Name:</b> {i.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Phone:</b> {i.phoneNumber}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Email:</b> {i.email}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <UpdateAction {...{ i }} />
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div> */}
        </>
      ) : (
        <>
          <Title>No UserList</Title>
        </>
      )}
    </Container>
  );
};
export default TableVasthu;
// export default function FullFeaturedCrudGrid() {
//   const [rows, setRows] = React.useState();
//   const [rowModesModel, setRowModesModel] = React.useState({});

//   const columns = [
//     { field: 'name', headerName: 'Name', width: 180, editable: true },
//     {
//       field: 'age',
//       headerName: 'Age',
//       type: 'number',
//       width: 80,
//       align: 'left',
//       headerAlign: 'left',
//       editable: true,
//     },
//     {
//       field: 'joinDate',
//       headerName: 'Join date',
//       type: 'date',
//       width: 180,
//       editable: true,
//     },
//     {
//       field: 'role',
//       headerName: 'Department',
//       width: 220,
//       editable: true,
//       type: 'singleSelect',
//       valueOptions: ['Market', 'Finance', 'Development'],
//     },
//     {
//       field: 'actions',
//       type: 'actions',
//       headerName: 'Actions',
//       width: 100,
//       cellClassName: 'actions',
//       getActions: ({ id }) => {
//         var isInEditMode = rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit;

//         if (isInEditMode) {
//           return [
//             <GridActionsCellItem
//               icon={<SaveIcon />}
//               label="Save"
//               sx={{
//                 color: 'primary.main',
//               }}
//               onClick={handleSaveClick(id)}
//             />,
//             <GridActionsCellItem
//               icon={<CancelIcon />}
//               label="Cancel"
//               className="textPrimary"
//               onClick={handleCancelClick(id)}
//               color="inherit"
//             />,
//           ];
//         }

//         return [
//           <GridActionsCellItem
//             icon={<EditIcon />}
//             label="Edit"
//             className="textPrimary"
//             onClick={handleEditClick(id)}
//             color="inherit"
//           />,
//           <GridActionsCellItem
//             icon={<DeleteIcon />}
//             label="Delete"
//             onClick={handleDeleteClick(id)}
//             color="inherit"
//           />,
//         ];
//       },
//     },
//   ];

//   return (
//     <Box
//       sx={{
//         height: 500,
//         width: '100%',
//         '& .actions': {
//           color: 'text.secondary',
//         },
//         '& .textPrimary': {
//           color: 'text.primary',
//         },
//       }}
//     >
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         editMode="row"
//         rowModesModel={rowModesModel}
//         onRowModesModelChange={handleRowModesModelChange}
//         onRowEditStop={handleRowEditStop}
//         processRowUpdate={processRowUpdate}
//         slotProps={{
//           toolbar: { setRows, setRowModesModel },
//         }}
//       />
//     </Box>
//   );
// }
