import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../../component/Api";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import ChatRoom from "../ChatRoom";
import dayjs from "dayjs";
import NewBootCard from "../../component/NewBootCard";
import DatePicker from "react-date-picker";

function ConsultantChatHistory() {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [chatData, setChatData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  console.log("chatHistory", chatHistory);

  // ALERT MODAL
  const [chatTrigger, setChatTrigger] = useState(0);

  const chatRoom = () => {
    setChatTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/initiateChat/getChatHistoryForConsultant/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRefresh(false);
          setLoading(false);
          // setChatHistory(res.data.reverse());
          setChatHistory(res.data);
          // setChatHistory(res.data.chatHistory);
        });
    } catch (e) {
      console.log("setAllOffer", e);
      setLoading(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      vasthuUrl
        .get(`/initiateChat/getChatHistoryForConsultantByDate/${userId}`, {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            // consultantName: selectedConsultant,
          },
        })
        .then((res) => {
          const data = res.data.chatHistory;
          if (data.length == 0) {
            setChatHistory("");
          } else {
            setChatHistory(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching filtered data:", error);
        });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = () => {
      vasthuUrl
        .get(`/initiateChat/getchatdetailsforconsultant/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status == "in-progress") {
            setChatData(res.data);
            setRefresh(true);
            console.log("res.data", res.data);
          } else {
            setChatData();
          }
        })
        .catch((error) => {
          console.log("SERVER ERROR", error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    { field: "username", headerName: t("USR.HOM.HD.UN"), width: 120 },
    // { field: "conName", headerName: "CONSULTANT NAME", width: 150 },
    { field: "status", headerName: t("TXT.STS.LO"), width: 130 },
    { field: "duration", headerName: t("USR.CHAT.MIN"), width: 130 },
    { field: "amountToPaid", headerName: "Earnings", width: 130 },
    { field: "proposedChatDuration", headerName: t("USR.TOT.DUR"), width: 130 },
    {
      field: "actions",
      headerName: "ACTION",
      renderCell: (params) =>
        params.row.status === "in-progress" ? (
          <Button className="all_btn" onClick={() => navigate("chatscreen")}>
            JOIN
          </Button>
        ) : (
          "-"
        ),
    },
    { field: "meetingCode", headerName: t("USR.METNG.CDE"), width: 120 },
    {
      field: "chatDate",
      headerName: t("USR.CHAT.DATE"),
      renderCell: (params) => {
        const date = new Date(params.row.chatDate);
        return format(date, "dd-MM-yyyy");
      },
      width: 120,
    },
    { field: "chatStartTime", headerName: t("USR.CHAT.STA.TIME"), width: 160 },
    {
      field: "connectStartTime",
      headerName: t("USR.CONNECT.STAT.TIME"),
      width: 170,
    },
    { field: "connectEndTime", headerName: t("USR.CONT.END.TIME"), width: 170 },
  ];

  return (
    <div>
      <center className="mt-2">
        {chatData && chatData.status == "in-progress" ? (
          <>
            <Row>
              {chatData.astroUserDetails ? (
                <Col
                  style={{
                    border: "1px solid #ffb33c",
                    backgroundColor: "antiquewhite",
                    padding: "20px",
                    borderBottomRightRadius: "5px",
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  className="mx-auto text-center"
                >
                  <Card.Header>
                    <h4 style={{ color: "#b11b1b" }}>PERSONAL DETAILS</h4>
                  </Card.Header>
                  <Card.Text>
                    Name: {chatData.astroUserDetails.userName}
                  </Card.Text>
                  <Card.Text>
                    Date Of Birth: {chatData.astroUserDetails.birthDate}
                  </Card.Text>
                  <Card.Text>
                    Birth Time: {chatData.astroUserDetails.birthTime}
                  </Card.Text>
                  <Card.Text>
                    Birth Place: {chatData.astroUserDetails.userBirthstate}
                  </Card.Text>
                </Col>
              ) : (
                <div className="d-flex flex-wrap justify-content-center g-3">
                  {chatData.astroMatchingDetails ? (
                    <>
                      <Col
                        style={{
                          border: "1px solid #ffb33c",
                          backgroundColor: "antiquewhite",
                          padding: "25px",
                        }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <Card.Header className="text-center">
                          <h4 style={{ color: "#b11b1b" }}>MALE</h4>
                        </Card.Header>
                        <Card.Text>
                          Name: {chatData.astroMatchingDetails.maleUserName}
                        </Card.Text>
                        <Card.Text>
                          Date Of Birth:{" "}
                          {chatData.astroMatchingDetails.maleBirthDate}
                        </Card.Text>
                        <Card.Text>
                          Birth Time:{" "}
                          {chatData.astroMatchingDetails.maleBirthTime}
                        </Card.Text>
                        <Card.Text>
                          Birth Place:{" "}
                          {chatData.astroMatchingDetails.maleBirthPlace}
                        </Card.Text>
                      </Col>
                      <Col
                        style={{
                          border: "1px solid #ffb33c",
                          backgroundColor: "antiquewhite",
                          padding: "25px",
                        }}
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <Card.Header className="text-center">
                          <h4 style={{ color: "#b11b1b" }}>FEMALE</h4>
                        </Card.Header>
                        <Card.Text>
                          Name: {chatData.astroMatchingDetails.femaleUserName}
                        </Card.Text>
                        <Card.Text>
                          Date Of Birth:{" "}
                          {chatData.astroMatchingDetails.femaleBirthDate}
                        </Card.Text>
                        <Card.Text>
                          Birth Time:{" "}
                          {chatData.astroMatchingDetails.femaleBirthTime}
                        </Card.Text>
                        <Card.Text>
                          Birth Place:{" "}
                          {chatData.astroMatchingDetails.femaleBirthPlace}
                        </Card.Text>
                      </Col>
                    </>
                  ) : (
                    "No Data Available"
                  )}
                </div>
              )}
            </Row>
            <center>
              <Card
                className="d-flex p-3 all_border mb-4 mt-3"
                style={{ width: "70%", height: "auto" }}
              >
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Card.Text>
                      <b>
                        <h4>
                          {t("USR.CHAT.CUSTOMER")}: {chatData.customerName}
                        </h4>
                      </b>
                    </Card.Text>
                    <Card.Text>
                      {t("USR.METNG.CDE")}: {chatData.meetingCode}
                    </Card.Text>
                    <Card.Text>
                      {t("TXT.STS.LO")}: {chatData.status}
                    </Card.Text>
                    <Card.Text>
                      {t("USR.INTI.TIME")}: {chatData.chatStartTime}
                    </Card.Text>
                    <Card.Text>
                      {t("USR.PROPO.DUR")}: {chatData.proposedChatDuration}
                    </Card.Text>
                  </Col>

                  {/* <Col xs={6} sm={6} md={6}>
                    {" "}
                    <Card.Text>
                      <b>
                        <h4>
                          {" "}
                          <b>:</b>&nbsp;&nbsp;
                        </h4>
                      </b>
                    </Card.Text>
                    <Card.Text>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;
                    </Card.Text>
                    <Card.Text>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;
                    </Card.Text>
                    <Card.Text>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;
                    </Card.Text>
                    <Card.Text>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;
                    </Card.Text>
                  </Col> */}
                  {/* <Col xs={0} sm={0} md={2}></Col> */}
                </Row>
                <div className="mt-2">
                  <Button
                    className="all_btn"
                    onClick={() => navigate("chatscreen")}
                  >
                    {t("USR.CNF.CHAT.BTN")}
                  </Button>
                </div>
              </Card>
            </center>
          </>
        ) : null}
      </center>

      <Row className="mt-2 consul_chat_his" style={{ alignItems: "flex-end" }}>
        <Col sm={12} md={4} className="mt-2">
          {/* <input
                  type="text"
                  className="form-control form-input form_feild"
                  placeholder={t("CM.CNSL.SER.PL")}
                  onChange={(e) => setSearchField(e.target.value)}
                /> */}
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            Start Date:
            <DatePicker
              className="form_feild"
              value={startDate}
              format="dd-MM-yy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Select start date"
              style={{ height: "35px", borderRadius: "7px" }}
              maxDate={endDate ? endDate : today}
            />
          </label>
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            End Date:
            <DatePicker
              className="form_feild"
              value={endDate}
              format="dd-MM-yy"
              onChange={(date) => setEndDate(date)}
              placeholderText="Select end date"
              minDate={startDate}
              maxDate={today}
            />
          </label>
        </Col>
      </Row>

      {!loading ? (
        chatHistory.chatHistory && chatHistory.chatHistory.length > 0 ? (
          <>
            <center>
              <h3>
                <u>Chat History</u>
              </h3>
            </center>
            <Accordion className="chat_accordian mt-2" defaultActiveKey="0">
              <Accordion.Item
                className="accordian_style chat_accordian"
                eventKey="0"
              >
                <Accordion.Header>
                  <Row style={{ width: "inherit" }}>
                    <Col sm={12} md={6}>
                      <h5>Duration: {chatHistory.totalDuration}</h5>
                    </Col>
                    <Col sm={12} md={6}>
                      <h5>Salary: ₹{chatHistory.salaryAmount}</h5>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="chat_acc_body">
                  <div>
                    <center className="datagrid mt-3">
                      <Grid container className="deskTable datagrid">
                        <DataGrid
                          className="all_border text-center me-3 data_grid"
                          columns={columns}
                          rows={chatHistory.chatHistory || []}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowId={(row) => row.id}
                        />
                      </Grid>
                    </center>
                    <div className="mobileCard justify-content-center">
                      {chatHistory.chatHistory.map((item, idx) => (
                        <NewBootCard key={idx}>
                          {/* <h6>User: {item.callFrom}</h6> */}
                          <h6>Client: {item.username}</h6>
                          <h6>Status: {item.status}</h6>
                          <h6>Duration: {item.duration}</h6>
                          <h6>Earnings:{item.amountToPaid}</h6>
                          <h6>Total Duration: {item.proposedChatDuration}</h6>
                          <h6>Client: {item.username}</h6>
                          <h6>Meeting Code: {item.meetingCode}</h6>
                          <h6>Chat Date: {item.chatDate}</h6>
                          <h6>Chat Start Time: {item.chatStartTime}</h6>
                          <h6>Connect Start Time: {item.connectStartTime}</h6>
                          <h6>Connect EndTime: {item.connectEndTime}</h6>
                        </NewBootCard>
                      ))}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        ) : (
          <center>
            <h5>No Records Found</h5>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </div>
  );
}

export default ConsultantChatHistory;
