import React, { useState, useEffect } from "react";
import { vasthuUrl } from "../component/Api";
import { Button } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Toast from "../component/Toast";

const AudioCallAdmin = () => {
  const { t } = useTranslation();
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([]);
  const [render, setRender] = useState(false);
  const token = window.sessionStorage.getItem("token");

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  // Fetch audio call history
  useEffect(() => {
    const fetchAudioCallHistory = async () => {
      try {
        const response = await vasthuUrl.get(
          `vasthu/user/getAudioCallHistory`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAudiocallHistoryList(response.data);
        setRender(false);
      } catch (error) {
        console.error("Error fetching call history:", error);
      }
    };

    fetchAudioCallHistory();
  }, [token, render]);

  const handleUpdateStatus = async (data) => {
    console.log("data", data);
    try {
      const res = await vasthuUrl.put(
        `updateCallStatus`,
        {
          id: data.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(`Status Updated Successfully !!!`);
      setMessageType("success");
      setRender(true);
    } catch (error) {
      alert("Failed to Update");
      setMessageType("error");
    }
  };

  const column = [
    { field: "callTo", headerName: "Client", width: 150 },
    { field: "callFrom", headerName: "Consultant", width: 130 },
    { field: "duration", headerName: "Duration", width: 100 },
    {
      field: "start_time",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.row.start_time);
        return format(date, "dd-MM-yyyy HH:mm");
      },
    },

    {
      field: "proposedCallDuration",
      headerName: "Proposed Duration",
      width: 150,
    },
    {
      field: "offerName",
      headerName: "Offer",
      width: 150,
      renderCell: (params) =>
        params.row.offerName ? params.row.offerName : "Wallet Call",
    },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "actions",
      headerName: "ACTION",
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          {params.row.status == "in-progress" ? (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row)}
            >
              Update
            </Button>
          ) : (
            "-"
          )}
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <React.Fragment>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />
      <div>
        <center>
          <u>
            <h2>{t("USR.AUDCH.TEXT1")}</h2>
          </u>
        </center>
      </div>
      <center className="datagrid">
        <Grid container className="deskTable">
          <DataGrid
            className="all_border text-center me-3 data_grid"
            rows={[...audiocallHistoryList].reverse()}
            columns={column}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row.id}
          />
        </Grid>
      </center>

      <div className="mobileCard justify-content-center mx-1">
        {audiocallHistoryList.map((item, index) => (
          <NewBootCard className="p-3 mb-2 bg-secondary text-white" key={index}>
            <h4 className="text-center">
              {"Client"}: {item.callTo}
            </h4>
            <hr />
            <h6>
              {"Consultant"}: {item.callFrom}
            </h6>
            <h6>
              {"Duration"}: {item.duration}
            </h6>
            <h6>
              {"Proposed Duration"}: {item.proposedCallDuration}
            </h6>
            <h6>
              {"Offer"}: {item.offerName}
            </h6>
            <h6>
              {"Status"}: {item.status}
            </h6>
            {/* <Button className="mx-1 all_btn" onClick={() => openModal(item.id)}>
              Change
            </Button> */}
          </NewBootCard>
        ))}
      </div>
    </React.Fragment>
  );
};

export default AudioCallAdmin;
