import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import NewBootCard from "../component/NewBootCard";
import FeedBack from "../component/FeedBack";
import chatingbg from ".././images/chatingbg.jpg";
import Toast from "../component/Toast";

function ChatHistory() {
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const [ChatHistoryDetails, setChatHistoryDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  //Review Modal
  const [openModal, setOpenModal] = useState(false);
  const [profDetails, setProfDetails] = useState();

  const showModal = (i) => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setProfDetails(null); // Reset modal details when closed
  };

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  //View Chat
  const [viewChat, setViewChat] = useState(false);
  const [chatData, setChatData] = useState();

  const showViewChat = (i) => {
    setViewChat(true);
    setChatData(i);
  };

  const closeViewChat = () => {
    setViewChat(false);
  };

  const handleGetChat = (meetingCode) => {
    setLoading(true);
    vasthuUrl
      .get(`/getChatRecords/${meetingCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        showViewChat(res.data);
        setLoading(false);
      })
      .catch((er) => {
        alert(t("TXT.NO.REC.FUD.LO"));
        setMessageType("error");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`/initiateChat/getChatHistory/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("achistory", rs.data);
        setChatHistoryDetails(rs.data);
        setLoading(false);
      })
      .catch((er) => {
        console.log("call history err", er);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />

      <center>
        <h2>{t("USR.CHAT.HIS.HEAD")}</h2>
      </center>
      {!loading ? (
        ChatHistoryDetails.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable all_border text-center"
            >
              <thead>
                <tr>
                  <th>{t("USR.HOM.HD.UN")}</th>
                  <th>{t("USR.CHAT.CON.NAME")}</th>
                  <th>{t("TXT.STS.LO")}</th>
                  <th>{t("USR.CHAT.MIN")}</th>
                  <th>{t("USR.TOT.DUR")}</th>
                  {/* <th>Meeting Code</th> */}
                  <th>{t("USR.CHAT.DATE")}</th>
                  <th>{t("USR.CHAT.STA.TIME")}</th>
                  <th>{t("USR.CONT.END.TIME")}</th>
                  <th>{t("USR.FD.BCK.BTN")}</th>
                  <th>{t("USR.VSH.WINTAB.TBL.CLMN.ACT")}</th>
                </tr>
              </thead>
              <tbody>
                {ChatHistoryDetails.slice()
                  .reverse()
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.username}</td>
                      <td>{item.conName}</td>
                      <td>{item.status}</td>
                      <td>{item.duration}</td>
                      <td>{item.proposedChatDuration}</td>
                      {/* <td>{item.meetingCode}</td> */}
                      <td>{item.chatDate}</td>
                      <td>{item.chatStartTime.slice(0, 5)}</td>
                      <td>
                        {item.connectEndTime
                          ? item.connectEndTime.slice(0, 5)
                          : "-"}
                      </td>

                      {/* <td>{item.connectStartTime}</td>
                                        <td>{item.connectEndTime}</td> */}
                      <td>
                        {item.status == "DISCONNECTED" ? (
                          <Button
                            variant="outline-warning"
                            onClick={() => {
                              console.log("II", item);
                              showModal();
                              setProfDetails(item);
                            }}
                          >
                            {t("USR.HOM.REV.TEXT4")}
                          </Button>
                        ) : (
                          "-"
                        )}
                      </td>
                      {/* <td>
                        {item.duration > 0 ? (
                          <Button
                            variant="outline-warning"
                            onClick={() => {
                              handleGetChat(item.meetingCode);
                            }}
                          >
                            {t("TXT.VIEW.LO")}
                          </Button>
                        ) : (
                          "-"
                        )}
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>

            {openModal ? (
              <FeedBack
                details={profDetails}
                params={true}
                onClose={closeModal}
              />
            ) : null}

            <div className=" mobileCard justify-content-center mx-1">
              {ChatHistoryDetails.slice()
                  .reverse()
                  .map((item, j) => (
                <NewBootCard key={j}>
                  <h4 className="text-center">
                    <td>
                      {" "}
                      {t("USR.HOM.HD.UN")}:{item.username}
                    </td>
                  </h4>
                  <h6>
                    <td>
                      {t("USR.CHAT.CON.NAME")}:{item.conName}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("TXT.STS.LO")}:{item.status}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.MIN")}:{item.duration}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.TOT.DUR")}:{item.proposedChatDuration}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.DATE")}:{item.chatDate}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.STA.TIME")}:{item.chatStartTime.slice(0, 5)}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CONT.END.TIME")}{" "}
                      {item.connectEndTime
                        ? item.connectEndTime.slice(0, 5)
                        : "-"}
                    </td>
                  </h6>
                  <div className="d-flex justify-content-evenly flex-row">
                    <td>
                      {item.status == "DISCONNECTED" ? (
                        <Button
                          variant="outline-warning"
                          onClick={() => {
                            console.log("II", item);
                            showModal();
                            setProfDetails(item);
                          }}
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* <td>
                      {item.duration > 0 ? (
                        <Button
                          variant="outline-warning"
                          onClick={() => {
                            handleGetChat(item.meetingCode);
                          }}
                        >
                          {t("TXT.VIEW.LO")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td> */}
                  </div>
                </NewBootCard>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Modal
        show={viewChat}
        onHide={closeViewChat}
        // size="lg"
        centered
        fullscreen={true}
        style={{ height: "100vh" }}
      >
        <div className="chat_view_main">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundImage: `url(${chatingbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "overlay",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
            }}
            className="chat_view_back"
          >
            <ul
              style={{
                listStyleType: "none",
                padding: "0",
                overflow: "auto",
              }}
            >
              {chatData && chatData.messages.length > 0
                ? chatData.messages.map((chat, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent:
                          chat.role == "ROLE_USER" ? "flex-end" : "flex-start",
                        margin: "10px 0",
                        overflowWrap: "anywhere",
                      }}
                    >
                      <div
                        style={{
                          // maxWidth: "70%",
                          padding: "10px",
                          backgroundColor:
                            chat.role == "ROLE_USER" ? "#d1e7dd" : "#f8d7da",
                          borderRadius: "10px",
                        }}
                        className="chat_text_message"
                      >
                        {chat.role == "ROLE_USER" ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: chat.message.replaceAll("\n", "<br />"),
                              }}
                            />
                            {/* {chat.message} */}
                            {/* <strong> : {userData.username}</strong> */}
                          </>
                        ) : (
                          <>
                            <strong>{chat.senderName} : </strong>
                            {/* {chat.message} */}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: chat.message.replaceAll("\n", "<br />"),
                              }}
                            />
                          </>
                        )}
                      </div>
                    </li>
                  ))
                : t("TXT.NO.REC.FUD.LO")}
            </ul>
          </div>
        </div>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Button className="all_btn" onClick={closeViewChat}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChatHistory;
