import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomerSpecialPricing from "../../pages/CustomerSpecialPricing";
import CustomerOneTimePricing from "../../pages/CustomerOneTimeService";
import WalletOffer from "../../pages/WalletOffer";

const PaymentOption = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const crmUuid = location && location.state && location.state.row.uuid;

  return (
    <Tabs
      defaultActiveKey="wallet"
      id="PaymentOption"
      className="mb-3 mt-3"
      justify
    >
      <Tab eventKey="wallet" title="Wallet Offer">
        <WalletOffer crmUuid={crmUuid} />
      </Tab>
      <Tab eventKey="special" title={t("USR.HOM.SPCL.OFF")}>
        <CustomerSpecialPricing crmUuid={crmUuid} />
      </Tab>
      <Tab eventKey="onetime" title={t("USR.PR.OTTAB")}>
        <CustomerOneTimePricing crmUuid={crmUuid} />
      </Tab>
    </Tabs>
  );
};

export default PaymentOption;
