import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Alert, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import BarChart from "../component/BarChart";
import { UseAuth } from "../context/Auth";
import { useTranslation, Trans } from "react-i18next";

const AdminDash = () => {
  const { t } = useTranslation();
  const { token } = UseAuth();
  // const token = window.localStorage.getItem("token");
  const [credentialCount, setCredentialCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [refundCount, setRefundCount] = useState({});
  console.log("refundCount", refundCount);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/data/getallusercount`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCredentialCount(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/getAllPayment`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRefundCount(res.data);
          console.log("res.data.length", res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  return (
    <Container>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        <Row
          className="adminDash my-2"
          style={{ width: "100%", height: "auto", paddingLeft: 25 }}
        >
          <Col sm={12} md={4}>
            <Card
              bg="light"
              key="light"
              style={{ width: "100%", height: "auto" }}
              className="text-center my-1 all_border"
            >
              <Card.Header className="all_background" as="h6">TOTAL CUSTOMER</Card.Header>
              <Card.Body>
                <Card.Title>
                  {" "}
                  {credentialCount.userCount
                    ? credentialCount.userCount
                    : 0}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4}>
            <Card
              bg="light"
              key="light"
              style={{ width: "100%", height: "auto" }}
              className="text-center my-1 all_border"
            >
              <Card.Header className="all_background" as="h6">TOTAL CONSULTANT</Card.Header>
              <Card.Body>
                <Card.Title>
                  {" "}
                  {credentialCount.professionalCount
                    ? credentialCount.professionalCount
                    : 0}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4}>
            <Card
              bg="light"
              key="light"
              style={{ width: "100%", height: "auto" }}
              className="text-center my-1 all_border"
            >
              <Card.Header className="all_background" as="h6">TOTAL ADMIN</Card.Header>
              <Card.Body>
                <Card.Title>
                  {" "}
                  {credentialCount.adminCount ? credentialCount.adminCount : 0}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4}>
            <Card
              bg="light"
              key="light"
              style={{ width: "100%", height: "auto" }}
              className="text-center my-1 all_border"
            >
              <Card.Header className="all_background" as="h6">TOTAL CRM</Card.Header>
              <Card.Body>
                <Card.Title>
                  {" "}
                  {credentialCount.crmCount ? credentialCount.crmCount : 0}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col sm={12} md={4}>
            <Card
              bg="light"
              key="light"
              style={{ width: "100%", height: "auto" }}
              className="text-center my-1 all_border"
            >
              <Card.Header className="all_background" as="h6">TOTAL REFUND</Card.Header>
              <Card.Body>
                <Card.Title>
                  {refundCount.length ? refundCount.length : 0}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
      {/* <Row>
        <BarChart
          data={[
            [t("Admin.0006"), t("Admin.0001"), t("Admin.0002"), t("Admin.0003")],
            ["2014", 1000, 400, 200],
            ["2015", 1170, 460, 250],
            ["2016", 660, 1120, 300],
            ["2018", 1030, 540, 350],
            ["2019", 1700, 540, 350],
            ["2020", 1300, 560, 350],
            ["2021", 1800, 640, 350],
            ["2022", 1430, 500, 350],
          ]}
          options={{
            chart: {
              title: t("Admin.0007"),
              subtitle: t("Admin.0001") + ", " + t("Admin.0002") + ", " + t("Admin.0005") +" "+ t("Admin.0003")+ ": 2014-2022",
            },
            bars: "vertical",
          }}
        />
      </Row> */}
    </Container>
  );
};

export default AdminDash;
