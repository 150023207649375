import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "react-bootstrap";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function UserChatList(params) {
  console.log("params", params.userChatList);
  const userChatList = params.userChatList;
  console.log("userChatList", userChatList);
  const { t } = useTranslation();

  const columns = [
    { field: "conName", headerName: "CONSULTANT", width: 150 },
    { field: "chatType", headerName: "CHAT TYPE", width: 150 },
    { field: "connectStartTime", headerName: "START TIME", width: 200 },
    { field: "duration", headerName: "DURATION", width: 150 },
    {
      field: "proposedChatDuration",
      headerName: "PROPOSED DURATION",
      width: 200,
    },
    { field: "status", headerName: "STATUS", width: 200 },
  ];

  return (
    <div>
      <center>
        <h4>
          <u>Chat Details</u>
        </h4>
      </center>
      {userChatList.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={userChatList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          <div className="mobileCard">
            {userChatList.map((i, j) => (
              <Card
                className="mobileCard all_border"
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Consultant:</b> {i.conName}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Chat Type:</b> {i.chatType}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Start Time:</b> {i.connectStartTime}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Duration:</b> {i.duration}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Proposed Duration:</b> {i.proposedChatDuration}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Status:</b> {i.status}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}
    </div>
  );
}

export default UserChatList;
