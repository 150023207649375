import React, { useState } from "react";
import MinutesMaster from "./MinutesMaster";
import BannerList from "./BannerList";
import { Tab, Tabs } from "react-bootstrap";
import SalaryPercentage from "./SalaryPercentage";
import WalletOfferList from "./WalletOfferList"

const Masters = () => {
    const [key, setKey] = useState("minutesmaster");

    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="justify-content-center"
      >
        <Tab eventKey="walletofferlist" title="WALLET">
          <WalletOfferList />
        </Tab>
        <Tab eventKey="minutesmaster" title="MINUTES MASTER">
          <MinutesMaster />
        </Tab>
        <Tab eventKey="bannerlist" title="BANNER LIST">
          <BannerList />
        </Tab>
        <Tab eventKey="salarypercentage" title="CONSULTANT SALARY">
          <SalaryPercentage />
        </Tab>
      </Tabs>
    );
}

export default Masters