import React from "react";
import { Routes, Route } from "react-router-dom";
import NoMatch from "../pages/Nopage";
import Layout from "../pages/crm/Layout";
import DashBoard from "../pages/crm/DashBoard";
import Accounts from "../pages/crm/Accounts";
import BookConsultant from "../pages/BookConsultant";
import CustomerBooking from "../pages/CustomerBooking";
import Search from "../component/Search";
import CheckConsultant from "../pages/CheckConsultant";
import AppointTableDesk from "../pages/AppointTableDesk";
import UserAppointHistory from "../pages/UserAppointHistory";
import CustomerPayment from "../pages/CustomerPayment";
import { PaymentProvider } from "../context/PaymentContext";
import HomeConsultant from "../component/HomeConsultant";
import AudioCallStatus from "../component/AudioCallStatus";
import AstroUserDetails from "../pages/User/AstroUserDetails";
import UsersList from "../pages/crm/UsersList";
import Offer from "../pages/crm/Offer";
import Payments from "../pages/crm/Payments";
import Report from "../pages/crm/Report";
import CustomerRecord from "../pages/CustomerRecords";
import CustomerPricing from "../pages/CustomerPricing";
import { VasthuProvider } from "../context/vasthu";
import Translation from "../pages/crm/Translation";
import CustomerAccount from "../pages/CustomerAccount";
import PaymentOption from "../component/crm/PaymentOption";

const CrmRoute = () => {
  return (
    <VasthuProvider>
      <PaymentProvider>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<DashBoard />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="consultantbook" element={<CustomerBooking />} />
            <Route path="consultantbooking" element={<BookConsultant />} />
            <Route path="consultantList" element={<CustomerBooking />} />
            <Route path="checkconsultant" element={<CheckConsultant />} />
            <Route path="consultantbooklist" element={<AppointTableDesk />} />
            <Route path="appointmenthistory" element={<UserAppointHistory />} />
            <Route path="customerpayment" element={<CustomerPayment />} />
            <Route path="homeconsultant" element={<HomeConsultant />} />
            <Route path="audiocallstatus" element={<AudioCallStatus />} />
            <Route path="astroUserDetails" element={<AstroUserDetails />} />
            <Route path="userslist" element={<UsersList />} />
            <Route path="offerlist" element={<Offer />} />
            <Route path="buildingdetails" element={<CustomerRecord />} />
            <Route path="payments" element={<Payments />} />
            <Route path="customerpricing" element={<CustomerPricing />} />
            <Route path="translationlist" element={<Translation />} />
            <Route path="customeraccount" element={<CustomerAccount />} />
            <Route path="reports" element={<Report />} />
            <Route path="crmpayment" element={<PaymentOption />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </PaymentProvider>
    </VasthuProvider>
  );
};

export default CrmRoute;
